<template>
  <DataView
    v-bind="configRef"
    ref="dataView"
    class="data-view-class"
  >
    <template #header__after>
      <div>
        <Button
          class="btn-print"
          icon="pi pi-print"
          text
          @click="printData('/CatalogoTramos', 'Catálogo de Tramos')"
        >
        </Button>
      </div>
    </template>
    <template #footer>
      <div class="query__paginator-container--big">
        <Paginator
          v-if="pagination"
          :rows="pagination.pageSize"
          :page-link-size="5"
          :total-records="pagination.totalResults"
          @page="updatePagination"
        ></Paginator>
      </div>
      <div class="query__paginator-container--small">
        <Paginator
          v-if="pagination"
          :rows="pagination.pageSize"
          :page-link-size="1"
          :total-records="pagination.totalResults"
          @page="updatePagination"
        ></Paginator>
      </div>
    </template>
  </DataView>
</template>


<script setup>
import tableConfig from './length-road-type-by-ccaa-table.config';
import {
  DataView,
} from '@sttedra_ui/framework';
import {ref, onMounted, computed} from 'vue';
import {getAllCCOO} from '@/api/autonomous-communities/autonomous-communities.api';
import printData from '@/api/print/printCcaa.api';
import Button from 'primevue/button';
import Paginator from 'primevue/paginator';
// #region Props, Emits
// ...
// #endregion
// #region Params, Ref, Reactives, Computed
const state$$ = computed(() =>
  !!dataView.value && !!dataView.value.state$$ ? dataView.value.state$$ : null,
);
const pagination = computed(() =>
  !!state$$?.value?.pagination?.pageIndex ? state$$.value.pagination : null,
);
const cities = ref([]);
const ccooIdToFilter = ref([]);
const dataView = ref();
let smallScreen = false;

// #endregion
// #region Functions
// #endregion

const {configRef} = tableConfig(ccooIdToFilter.value);

function updatePagination(data) {
  const {rows, page: page0Index} = data;
  const page = page0Index + 1;
  const pagination = dataView.value?.state$$?.pagination;
  if (!!pagination.pageIndex && !!page) {
    pagination.pageIndex = page;
  }
  if (!!pagination.pageSize && !!rows) {
    pagination.pageSize = rows;
  }
  dataView.value.debounceRefreshAndUpdate();
}
function handleResize() {
  if (window.innerWidth <= 768 && !smallScreen) {
    smallScreen = true;
    setTimeout(() => (dataView.value.state$$.viewRef = 'list'), 10);
  }
  if (window.innerWidth > 768 && smallScreen) {
    smallScreen = false;
    setTimeout(() => (dataView.value.state$$.viewRef = 'table'), 10);
  }
}

// #region Effects, Hooks
onMounted(() => {
  getAllCCOO().then((res) => {
    cities.value = res;
  });
  if (window.screen.width <= 768) {
    setTimeout(() => (dataView.value.state$$.viewRef = 'list'), 10);
  }
  window.addEventListener('resize', handleResize);
});
// #endregion


// #region Provide, Expose
// ...
// #endregion
</script>


<style lang="scss" scoped>
.container {
  box-shadow: $brand__boxshadow--bottom;
      border-radius: $brand__borderradius--large;
      padding: $brand__boxspace--medium * 2;
      min-width: 800px;
      height: 100%;
      display:flex;
      justify-content: center;
      &__chart {
        width: 100%;
      }


}
.btn-print {
  display: none;
  /* cursor: pointer;
  margin-top: 4%;
  color: #211915;
  & :deep(.pi) {
    font-size: 1.35rem;
  }
  &:hover {
    background-color: #FFDB00 !important;
    border-radius: 15%;
  } */
}
.header-before-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}
.data-view-class {
  & :deep(.p-progressbar-value.p-progressbar-value-animate) {
    background-color: #FFDB00;
  }
  & :deep(.p-dropdown-label.p-inputtext) {
    background-color: transparent;
    font-family: 'Roboto';
    font-size: 14px;
  }
  & :deep(.p-dropdown-label.p-inputtext.p-placeholder) {
    font-family: 'Roboto';
    font-size: 14px;
  }
  & :deep(.data-view__header__after) {
    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }
  & :deep(.data-view__header__after > button) {
    margin-left: 0 !important;
  }
  & :deep(.p-button-secondary) {
    color: #211915 !important;
    background-color: white !important;
    border: none !important;
    &:hover, &:focus, &.p-disabled {
      background-color: #FFDB00 !important;
      border-radius: 15%;
      opacity: 1 !important;
    }
  }
}
.query__paginator-container--small {
  display: block;
  & :deep(.p-highlight) {
    background-color: #FFDB00;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.query__paginator-container--big {
  display: none;
  & :deep(.p-highlight) {
    background-color: #FFDB00;
  }
  @media screen and (min-width: 768px) {
    display: block;
  }
}
</style>
