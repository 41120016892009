import LengthProvincialByTypeView
  from '../views/length-provincial-by-type/LengthProvincialByTypeView.vue';

export default [
  {
    path: '/longitud-provincia-tipo',
    name: 'LengthProvincialByTypeView',
    component: LengthProvincialByTypeView,
    meta: {
      title: 'Longitud provincial por tipo de vía',
      bigImage: 'cabecera-carretera-01.jpg',
    },
  },
];
