// UI Theme
import '@/styles/index.scss';

import {createApp} from 'vue';
import {sync} from 'vuex-router-sync';
import PrimeVue from 'primevue/config';

import {
  // Plugins
  create$Fetch,
  createApplicationInsights,
  createAxios,
  createKeycloak,
  createMsal,
  // createAuthorization,
  createRouter,
  createStore,
  createWebVitals,

  // Directives
  roles,
  sortable,

  // Composables
  // useDev,

  // Config
  getAppConfig,
} from '@sttedra_ui/framework';

import App from './App.vue';
import {routes, hooks, routerOptions} from './routes';


// (!) Show all STTEDRA logs
// useDev.showAll(); useDev.hideAll(); useDev.show('YOUR_REGEXP');

// #region Creation
const {
  $fetchConfig,
  applicationInsightsConfig,
  axiosConfig,
  isProduction,
  keycloakConfig,
  msalConfig,
  primeVueOptions,
  webVitalsConfig,
} = getAppConfig();

const app = createApp(App);
const router = createRouter(routes, hooks, {routerOptions});

const appInsights = createApplicationInsights(applicationInsightsConfig);
const webVitals = createWebVitals(webVitalsConfig);
const $fetch = create$Fetch();
const axios = createAxios();
const keycloak = createKeycloak(keycloakConfig);
const msal = createMsal(msalConfig);
const store = createStore(isProduction);
// const authorization = createAuthorization();
// #endregion

// #region Mount
sync(store, router);

if (appInsights) {
  app.use(appInsights);
}

if (webVitals) {
  app.use(webVitals);
}

if (keycloak) {
  app.use(keycloak);
}

if (msal) {
  app.use(msal);
}

app.use($fetch, $fetchConfig);
app.use(PrimeVue, primeVueOptions);
app.use(axios, axiosConfig);
app.use(router);
app.use(store);

// app.use(authorization, {
//   /**
//    * @returns {string[]|{roles: string[]; data: any;}}
//    */
//   api: () => {...},
// });

app.directive('sortable', sortable);
app.directive('roles', roles);

let vm = null;
router.isReady()
  .then(() => {
    vm = app.mount('#' + VUE_APP_ID);
  });
// #endregion

export {vm}; // window.sttedra._root.vm
