import SectionsInauguratedView
  from '../views/sections-inaugurated/SectionsInauguratedView.vue';
export default [
  {
    path: '/tramos-inaugurados-rce',
    name: 'SectionsInauguratedView',
    component: SectionsInauguratedView,
    meta: {
      title: 'Tramos de la R.C.E. inaugurados',
      bigImage: 'cabecera-carretera-01.jpg',
    },
  },
];
