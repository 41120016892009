import routerOptions from './router.options';

import coreRoutes from './_core.routes';
import homeRoutes from './home.routes';
import remarksRoutes from './remarks.routes';
import downloadAllRoutes from './downloadAll.routes';
import lengthCcooProvinceTypeRoutes from './length-ccoo-province-type.routes';
import lengthTrackByRoadTypeRoutes from './length-track-by-road-type.routes';
import roadNomenclatureRceRoutes from './road-nomenclature-rce.routes';
import proposedFutureRoadsRoutes from './proposed-future-roads.routes';
import sectionsCededRoutes from './sections-ceded.routes';
import sectionsInauguratedRoutes from './sections-inaugurated.routes';
import provincialSectionsByRoadTypeRoutes from './provincial-sections-by-road-type.routes';
import lengthProvincialByTypeRoutes from './length-provincial-by-type.routes';
import afterEach from './hooks/after-each.hooks';
import beforeEach from './hooks/before-each.hooks';
import beforeResolve from './hooks/before-resolve.hooks';

const routes = [
  ...homeRoutes,
  ...remarksRoutes,
  ...downloadAllRoutes,
  ...lengthCcooProvinceTypeRoutes,
  ...lengthTrackByRoadTypeRoutes,
  ...roadNomenclatureRceRoutes,
  ...proposedFutureRoadsRoutes,
  ...sectionsCededRoutes,
  ...sectionsInauguratedRoutes,
  ...provincialSectionsByRoadTypeRoutes,
  ...lengthProvincialByTypeRoutes,
  ...coreRoutes, // (!) Always last
];

const hooks = {
  afterEach,
  beforeEach,
  beforeResolve,
};

export {
  hooks,
  routerOptions,
  routes,
};
