<template>
  <component
    :is="state$$.layout"
    :api-version="modelRef.apiVersion"
    :big-image="route?.meta?.bigImage"
    class="main-component"
  >
    <template #header__center>
      <div class="main-component__title">
        <span class="app-title">Catálogo Oficial de Carreteras</span>
        <span class="app-last-update">
          <span class="app-last-update__description">Última actualización</span>
          <span class="app-last-update__value">{{ lastUpdate }}</span>
        </span>
        <span class="route-title">{{ route.meta.title }}</span>
      </div>
    </template>
    <template #header__right>
      <div class="header-buttons m-2">
        <MobileMenu
          only-bars
          class="md:hidden m-1 p-1"
          @toggle-sidebar="toggleSidebar"
        />
      </div>
    </template>

    <template #main__center>
      <router-view />
      <div class="no-icon md:hidden sidebar-panel">
        <CollapsePanel
          ref="sidebarPanel"
          position="right"
        >
          <MobileMenu
            @click-route="(r) => clickRoute(r)"
          />
        </CollapsePanel>
      </div>
    </template>
  </component>
</template>

<script setup>
import {ref, nextTick, onMounted, provide, reactive} from 'vue';
import {useRouter, useRoute} from 'vue-router';
import {
  useImmer,
  useVuexModule,
  CollapsePanel,
} from '@sttedra_ui/framework';
import MobileMenu from '@/components/mobile-menu/MobileMenu';
import {getLastUpdate} from '@/api/fecha-actualizacion-datos/fecha-actualizacion-datos.api';

import AppViewModel from './app.view-model';
import AppViewState from './app.view-state';

// #region Props, Emits
// ...
// #endregion


// #region Params, Ref, Reactives, Computed
const state = new AppViewState();
const state$$ = reactive(state);
const router = useRouter();
const route = useRoute();
const sidebarPanel = ref();
const lastUpdate = ref('');

const model = new AppViewModel();
const {ref: modelRef /* , update: updateModel */} = useImmer(model);
let smallScreen = false;
const storeModule = useVuexModule('mainnav');
// #endregion


// #region Functions
function setRoutes() {
  const routes = modelRef.value.mainNavRoutes;
  storeModule.commit('setRoutes', routes);
}

function changeState() {
  console.debug(
    'My example properties are:',
    `"${state$$.property1}" and "${state$$.property2}"`,
  );

  setTimeout(() => {
    state$$.property1 = 'Dynamic value 1';
    state$$.property2 = 'Dynamic value 2';
  }, 3000);
}

function handleResize() {
  if (window.innerWidth <= 768 && !smallScreen) {
    smallScreen = true;
  }
  if (window.innerWidth > 768 && smallScreen) {
    smallScreen = false;
  }
}

function toggleSidebar() {
  sidebarPanel.value.toggle();
}
async function clickRoute(r) {
  closeSidebar();
  await router.push(r);
}
function closeSidebar() {
  sidebarPanel.value.toggle(false);
}
async function init() {
  lastUpdate.value = await getLastUpdate();
  window.addEventListener('resize', handleResize);
  // #region Examples
  changeState();
  setRoutes();
  // #endregion

  // #region Loading
  await nextTick();
  document.body.classList.add('app-mounted');
  // #endregion
}
// #endregion


// #region Effects, Hooks
onMounted(init);
// #endregion


// #region Provide, Expose
provide('appState$$', state$$);
// #endregion
</script>

<style lang="scss" scoped>
@import "src/styles";
.main-component {
  &__title {
    width: 100%;
    padding: 1rem 1rem 0 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    @include min-screen-width($brand__breakpoint--md) {
      font-size: 1.5rem;
    }
    & .app-title {
      order: 1;
      border-left: 5px solid #ffdb00;
      padding-left: 0.5rem;
      text-align: center;
      padding: 1rem 0.5rem;
      @include min-screen-width($brand__breakpoint--md) {
        text-align: left;
      }
    }
    & .app-last-update {
      order: 3;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: flex-end;
      text-align: center;
      width: 100%;
      padding: 0.3rem 0;
      &__description {
        padding-right: 1rem;
        @include min-screen-width($brand__breakpoint--md) {
          padding-right: 0;
        }
        &::after {
          content: ":";
          @include min-screen-width($brand__breakpoint--md) {
            content: none;
          }
        }
      }
      @include min-screen-width($brand__breakpoint--md) {
        order: 2;
        font-size: 1rem;
        flex-direction: column;
        justify-content: space-around;
        width: auto;
        padding: auto;
        &::after {
          content: none;
        }
      }
    }
    & .route-title {
      order: 2;
      text-align: right;
      padding-left: calc(5px + 0.5rem);
      @include min-screen-width($brand__breakpoint--md) {
        order: 3;
      }
    }
  }
  & :deep(.app__header) {
    height: auto !important;
    @include min-screen-width($brand__breakpoint--md) {
      display: none;
    }
  }
  & :deep(#buscador) {
    display: none !important;
  }
  & :deep(nav.brand) {
    height: auto !important;
  }
  & :deep(.p-dropdown) {
    border-bottom: 2px solid #211915;
    &:hover {
      border-bottom: 2px solid #FFDB00;
    }
    &:active {
      border-bottom: 2px solid #FFDB00;
    }
    &:focus {
      border-bottom: 2px solid #FFDB00;
    }
    &:focus-within {
      border-bottom: 2px solid #FFDB00;
    }
  }
  & :deep(.p-dropdown-trigger-icon) {
    color: #211915;
  }
  & :deep(th.p-resizable-column) {
    position: sticky !important;
    z-index: 100;
    top: 0;
    background-color: white;
    border-bottom: 2px solid $brand__color--lightblue;
    white-space: unset;
  }
  & :deep(td.p-editable-column) {
    white-space: unset;
  }
  & :deep(.basecard__content) {
    display: flex;
    flex-wrap: wrap;
  }
  & :deep(.basecard__content > div) {
    display: inline-block;
  }
  & :deep(.p-card-body) {
    margin-top: 1rem;
  }
  & :deep(.p-card-footer) {
    display: none;
  }
  & :deep(.data-view__info){
    flex-wrap: wrap;
  }
  & :deep(.data-view__header){
    background-color: white !important;
    border: 1px solid lightgrey;
    box-shadow: 4px 4px 12px rgba(0,0,0,0.15);
  }
  & :deep(.data-view-list-row){
    border-left: 2px solid #211915;
  }
  & :deep(.data-view-list-row__title){
    color: #211915 !important;
  }
}

.main-component :deep(.the-main-nav) {
  display: none !important;
  @include min-screen-width($brand__breakpoint--md) {
    display: block !important;
  }
}
.no-icon :deep(.p-button) {
  display: none;
}
.no-icon :deep(.collapse-panel) {
  padding: 0;
}
.sidebar-panel {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
  background-color: white;
  z-index: 200;
}
.header-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.main-component {
  & :deep(.app__header) {
    background-color: lightgray;
    @include min-screen-width($brand__breakpoint--md) {
      background-color: white;
    }
  }
  & :deep(.app__header__center) {
    padding-left: 1rem;
    font-weight: bold;
    font-size: 12px;

    @include min-screen-width($brand__breakpoint--md) {
      font-size: 20px;
    }
  }
  & :deep(.app__main__center) {
    padding-top: 0px;
  }
  & :deep(.app__main__center__header) {
    display: none;
  }
  & :deep(.the-main-nav__title) {
    background-color: white;
    color: black;
  }
  & :deep(.the-main-nav__container) {
    background-color: white;
    border-right: 1px solid lightgrey;
    border-left: none;
    & .item {
      & .item__link__anchor {
        &:hover {
          background-color: gray;
          border-right: 2px solid black;
        }
        &:active,
        &.item__link__anchor--active {
          background-color: #FFDB00;
          border-right: 2px solid black;
          filter: brightness(100%);
        }
        &.item__link__anchor {
          border-left: none;
        }
        &:focus {
          background-color: #FFDB00;
          border-right: 2px solid black;
          filter: brightness(100%);
        }
        & .item__link__anchor__title {
          color: black;
        }
        & .item__link__anchor__icon {
          color: black;
        }
      }
    }
  }
}
</style>
