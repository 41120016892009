import {
  useAxios,
  useHandleApiError,
  //  SearchApiModel
} from '@sttedra_ui/framework';
import ProvinceApiModel from './province.api-model';
/**
 * @typedef {import("@sttedra_ui/framework").AxiosErrorDataModel} AxiosErrorDataModel
 */

// #region Globals
const CONTEXT_BASE_PATH = 'Master/Provincias';
const CONTEXT_PARENT_BASE_PATH = 'Master/Ccaas';
const {base} = useAxios();
const {handleApiError} = useHandleApiError();
const timeoutRequestHttp = 30000;
// #endregion

const getAllProvinces = (idCcaa = 0) => {
  const getUrl = () => {
    const isFiltered = idCcaa !== 0 && idCcaa !== null && idCcaa !== undefined;
    if (isFiltered) {
      return `${CONTEXT_PARENT_BASE_PATH}/${idCcaa}/Provincias`;
    }
    return `${CONTEXT_BASE_PATH}`;
  };

  return base
    .get(getUrl(), {
      timeout: timeoutRequestHttp,
    })
    .then((res) => {
      const dataList = res.data.map((obj) => new ProvinceApiModel(obj));
      return dataList;
    })
    .catch(handleApiError);
};

export {getAllProvinces};
