import {useToNumber, useCheckType, types} from '@sttedra_ui/framework';
import {kmNumberFormat} from '../util-locale';

class LengthRoadTypeSummaryApiModel {
  // #region Props
  tipoVia;
  longitudRaw;
  longitud;
  id;
  // #endregion

  constructor({
    tipoVia,
    longitud,
    id,
  }) {
    this.tipoVia = this.#getString(tipoVia);
    this.longitudRaw = this.#getNumber(longitud);
    this.longitud = kmNumberFormat.format(this.longitudRaw);
    this.id = this.#getNumber(id);
  }

  #getNumber(newValue) {
    const number = useToNumber(newValue);
    return number >= 0 ? number : null;
  }
  #getString(newValue) {
    const expectedType = useCheckType(newValue, types.STRING);
    return expectedType ? newValue : null;
  }
}

export default LengthRoadTypeSummaryApiModel;
