import ProvincialSectionsByRoadTypeView
  from '../views/provincial-sections-by-road-type/ProvincialSectionsByRoadTypeView.vue';

export default [
  {
    path: '/tramos-provinciales-tipo-carretera',
    name: 'ProvincialSectionsByRoadTypeView',
    component: ProvincialSectionsByRoadTypeView,
    meta: {
      title: 'Tramos provinciales por tipo de vía',
      bigImage: 'cabecera-carretera-01.jpg',
    },
  },
];
