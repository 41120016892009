export const MAIN_NAV_ROUTES = [
  {
    name: 'CatalogSectionsView',
    title: 'Catálogo Tramos',
    icon: 'route',
  },
  {
    name: 'LengthCcooProvinceTypeView',
    title: 'Long. por CCAA, Provincia y tipo',
    icon: 'location_city',
  },
  {
    name: 'lengthTrackByRoadTypeView',
    title: 'Long. carretera por tipo de vía',
    icon: 'checklist_rtl',
  },
  {
    name: 'ProvincialSectionsByRoadTypeView',
    title: 'Tramos provinciales por tipo',
    icon: 'checklist',
  },
  {
    name: 'LengthProvincialByTypeView',
    title: 'Longitud provincial por tipo',
    icon: 'format_list_bulleted',
  },
  {
    name: 'roadNomenclatureRceView',
    title: 'Nomenclatura carreteras RCE',
    icon: 'category',
  },
  {
    name: 'ProposedFutureRoadsView',
    title: 'Propuestas futuras carreteras',
    icon: 'add_road',
  },
  {
    name: 'SectionsCededView',
    title: 'Cesiones',
    icon: 'swipe_right',
  },
  {
    name: 'SectionsInauguratedView',
    title: 'Inauguraciones',
    icon: 'new_releases',
  },
  {
    name: 'DownloadAllView',
    title: 'Descargar todo el catálogo',
    icon: 'download',
  },
  {
    name: 'RemarksView',
    title: 'Observaciones',
    icon: 'help',
  },
];

export default class AppViewModel {
  apiVersion = 'v1.0.17';
  mainNavRoutes = MAIN_NAV_ROUTES;
}
