import {useToNumber, useCheckType, types} from '@sttedra_ui/framework';
import {kmNumberFormat} from '@/api/util-locale';

class SectionsInauguratedApiModel {
  // #region Props
  carretera;
  clave;
  tramo;
  longitud;
  tipoVia;
  pkinicioT;
  pkfinT;
  ccaa;
  incrementoRed;
  fechaServicio;
  fechaServicioFin;
  // #endregion
  constructor({
    carretera,
    clave,
    tramo,
    longitud,
    tipoVia,
    pkinicioT,
    pkfinT,
    ccaa,
    incrementoRed,
    fechaServicio,
  }) {
    this.carretera = this.#getString(carretera);
    this.clave = this.#getString(clave);
    this.tramo = this.#getString(tramo);
    const longitudRaw = this.#getNumber(longitud);
    this.longitud = kmNumberFormat.format(longitudRaw);
    this.tipoVia = this.#getString(tipoVia);
    this.pkinicioT = this.#getString(pkinicioT);
    this.pkfinT = this.#getString(pkfinT);
    this.ccaa = this.#getString(ccaa);
    this.incrementoRed = this.#getString(incrementoRed);
    this.fechaServicio = this.#getDate(fechaServicio);
    this.fechaServicioFin = this.fechaServicio;
  }

  // #region Public methods

  // #endregion

  // #region Private methods
  #getNumber(newValue) {
    const number = useToNumber(newValue);
    return number >= 0 ? number : null;
  }
  #getString(newValue) {
    const expectedType = useCheckType(newValue, types.STRING);
    return expectedType ? newValue : null;
  }
  #getDate(newValue) {
    const auxDate = Date.parse(newValue);
    const date = new Date(auxDate).toLocaleDateString('es-ES');
    return date;
  }

  // #endregion
}

export default SectionsInauguratedApiModel;
