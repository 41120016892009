<template>
  <div class="catalog-layout">
    <div ref="camaleonHeader">
    </div>
    <!-- Elemento fantasma para evitar error JS camaleon -->
    <div
      id="ControlRutaNavegacion1_ruta"
      class="control-fantasma"
    ></div>
    <div
      v-if="bigImageSrc"
      class="catalog-layout__big-image"
    >
      <img :src="bigImageSrc" />
    </div>
    <TheLayout
      api-version="v1.0.17"
      class="main-component"
    >
      <template #header__left>
      </template>
      <template #header__center>
      </template>
      <template #header__right>
        <slot name="header__right"></slot>
      </template>
      <template #main__center>
        <div class="catalog-layout__main-center">
          <slot name="header__center"></slot>
          <slot name="main__center"></slot>
        </div>
      </template>
    </TheLayout>
    <p class="catalog-layout__version">
      {{ appVersion }}
    </p>
    <div ref="camaleonFooter">
    </div>
  </div>
</template>
<script setup>
import {ref, computed, nextTick, onMounted} from 'vue';
import {TheLayout, getPartToComposeLayout} from '@sttedra_ui/framework';

const camaleonHeader = ref(null);
const camaleonFooter = ref(null);

const props = defineProps({
  bigImage: {
    type: String,
    default: null,
  },
});

const bigImageSrc = computed(() => {
  if (!!props?.bigImage) {
    return '/statics/images/' + props.bigImage;
  }
  return null;
});
const appVersion = computed(() => 'Catálogo Oficial de Carreteras v' + VERSION);
// Copiado de sttedra TheComposeLayout.vue
function parseScriptsFromHTML(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const scripts = doc.querySelectorAll('script');
  const scriptArray = [];

  scripts.forEach((script) => {
    if (script.src) {
      // Script externo
      scriptArray.push({type: 'external', src: script.src});
    } else {
      // Script embebido
      const code = script.innerHTML.
        replace(/<!--|--><!\[CDATA\[\/\/><!\]\]>|\/\/><!\]\]>/g, '').trim();
      if (code) {
        scriptArray.push({type: 'inline', code});
      }
    }
  });

  return scriptArray;
}
// Copiado de sttedra TheComposeLayout.vue
function loadScript(script) {
  return new Promise((resolve, reject) => {
    const scriptEl = document.createElement('script');

    if (script.type === 'external') {
      scriptEl.src = script.src;
      scriptEl.onload = resolve;
      scriptEl.onerror = () => reject(new Error(`Script load error for ${script.src}`));
    } else if (script.type === 'inline') {
      scriptEl.text = script.code;
      resolve();
    } else {
      reject(new Error(`Unknown script type: ${script.type}`));
    }

    document.body.appendChild(scriptEl);
  });
}
// Copiado de sttedra TheComposeLayout.vue
function loadAndExecuteScripts(scripts) {
  let promiseChain = Promise.resolve();

  scripts.forEach((script) => {
    promiseChain = promiseChain.then(() => loadScript(script));
  });

  return promiseChain;
}
async function loadCamaleonScripts() {
  const options = {section: 'Scripts', applicationId: COMPOSE_LAYOUT_APPLICATION};
  const {data} = await getPartToComposeLayout(options);
  const scripts = parseScriptsFromHTML(data);
  loadAndExecuteScripts(scripts);
}
async function loadStyles() {
  const options = {section: 'styles', applicationId: COMPOSE_LAYOUT_APPLICATION};
  const {data} = await getPartToComposeLayout(options);
  document.head.insertAdjacentHTML('afterbegin', data);
}
async function getHeaderCamaleon() {
  const options = {section: 'Cabecera', applicationId: COMPOSE_LAYOUT_APPLICATION};
  const {data} = await getPartToComposeLayout(options);
  camaleonHeader.value.innerHTML = data;
}
async function getFooterCamaleon() {
  const options = {section: 'Pie1_TablaPie', applicationId: COMPOSE_LAYOUT_APPLICATION};
  const {data} = await getPartToComposeLayout(options);
  camaleonFooter.value.innerHTML = data;
}

onMounted(async () => {
  await loadStyles();
  await nextTick();
  await getHeaderCamaleon();
  await getFooterCamaleon();
  await loadCamaleonScripts();
});
</script>

<style lang="scss" scoped>
@import "src/styles";
  .catalog-layout {

    & :deep(.app__main__left) {
      display: none;
    }
    & .control-fantasma {
      display: none;
    }
    & :deep(.app) {
      height: 100% !important;
    }
    & :deep(.data-view__data .p-datatable) {
      height: 100% !important;
    }
    &__main-center {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    &__version {
      font-size: 0.9rem;
      margin: 0 !important;
      padding: 0.5rem 0;
      text-align: center;
      background-color: white;
      @include min-screen-width($brand__breakpoint--md) {
        text-align: right;
        padding-right: 1rem;
      }
    }
    &__big-image img {
      height: 75px; /* como serpavi */
      width: 100%;
      @include min-screen-width($brand__breakpoint--md) {
        height: 200px; /* como serpavi */
      }
    }
  }
</style>
