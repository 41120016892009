<template>
  <div class="combo-search__container">
    <InputText
      v-model="valueSearch"
      placeholder="Buscar..."
      class="w-full md:w-12rem m-2 input-search"
      type="search"
      @update:model-value="updateSearch"
    />
    <MultiSelect
      v-model="selectedFields"
      class="w-full m-2"
      :options="props.searchFields"
      option-label="label"
      placeholder="Campos de búsqueda"
      @update:model-value="updateSearch"
    >
      <template #value="{placeholder}">
        <span>{{ placeholder }}</span>
      </template>
    </MultiSelect>
  </div>
</template>

<script setup>
import {ref, computed} from 'vue';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
// #region Props, Emits
const props = defineProps({
  searchFields: {
    type: Array,
    required: true,
  },
});
const emit = defineEmits(['update:modelValue']);
// #endregion

// #region Params, Ref, Reactives, Computed
const valueSearch = ref(null);
const selectedFields = ref(props.searchFields); // Todos por defecto
const emitValue = computed(() => {
  return {
    search: valueSearch?.value,
    searchFields: selectedFields?.value,
  };
});
// #endregion

// #region Functions
function updateSearch() {
  emit('update:modelValue', emitValue?.value);
}
function resetSearch() {
  valueSearch.value = null;
}
// #endregion

// #region Effects, Hooks

// #endregion

// #region Provide, Expose
defineExpose({
  resetSearch,
});

// #endregion
</script>

<style lang="scss" scoped>
@import "src/styles";
.combo-search__container {
  display: flex;
  flex-direction: row;
  & :deep(.p-inputtext) {
    background-color: transparent;
  }
}
.input-search {
  background-color: white;
  margin-left: 0.7rem !important;
  border-bottom: 2px solid #211915;
  &:hover {
    border-bottom: 2px solid $solid-yellow;
  }
  &:focus {
    border-bottom: 2px solid $solid-yellow;
  }
}
</style>
