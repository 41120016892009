import {useToNumber, useCheckType, types} from '@sttedra_ui/framework';
import MasterBaseApiModel from '../_commons/master-base.api-model';

class AutonomousCommunitiesApiModel extends MasterBaseApiModel {
  // #region Props

  // #endregion

  constructor(data) {
    super(data);
  }

  // #region Public methods

  // #endregion

  // #region Private methods
  #getNumber(newValue) {
    const number = useToNumber(newValue);
    return number >= 0 ? number : null;
  }
  #getString(newValue) {
    const expectedType = useCheckType(newValue, types.STRING);
    return expectedType ? newValue : null;
  }
  // #endregion
}

export default AutonomousCommunitiesApiModel;
