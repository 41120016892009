import {
  useAxios,
  useHandleApiError,
} from '@sttedra_ui/framework';
import {dateFormat} from '@/api/util-locale';

// #region Globals
const {base} = useAxios();
const CONTEXT_BASE_PATH = 'configuration/LastUpdate';
const DEFAULT_TIMEOUT = 5000;
const {handleApiError} = useHandleApiError();
// #endregion

// #region Public functions
async function getLastUpdate() {
  const url = `/${CONTEXT_BASE_PATH}`;
  const options = {
    timeout: DEFAULT_TIMEOUT,
  };
  try {
    const {data} = await base.get(url, options);
    const date = new Date(data);
    return dateFormat.format(date);
  } catch (err) {
    handleApiError(err);
  }
}
// #endregion

// #region Private functions
// ...
// #endregion

export {
  getLastUpdate,
};
