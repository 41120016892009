<!-- eslint-disable max-len -->
<template>
  <div class="length-road-type-by-province-container">
    <DataView
      v-bind="configRef"
      ref="dataView"
      class="data-view-class"
    >
      <template #header__before>
        <div class="header-before-container">
          <Panel
            header="Filtros"
            class="panel"
          >
            <Dropdown
              v-model="selectedCcaa"
              :options="ccaas"
              option-label="nombre"
              placeholder="CCAA"
              class="w-full md:w-20rem m-2 dropdown"
              @update:model-value="updateCcaa"
            />
            <Dropdown
              v-model="selectedProvince"
              :options="provinces"
              option-label="nombre"
              placeholder="Provincia"
              class="w-full md:w-20rem m-2 dropdown"
              @update:model-value="updateProvince"
            />
          </Panel>
        </div>
      </template>
      <template #info>
        <div class="data-view__info">
          <div class="container-chips">
            <Chips
              v-if="!!selectedCcaaChip"
              v-model="selectedCcaaChip"
              class="prime-chips"
            />
            <Chips
              v-if="!!selectedProvinceChip"
              v-model="selectedProvinceChip"
              class="prime-chips"
            />
          </div>
        </div>
      </template>
      <template #header__after>
        <div>
          <Button
            class="btn-print"
            icon="pi pi-print"
            text
            @click="printData('/CatalogoTramos', 'Catálogo de Tramos')"
          >
          </Button>
        </div>
      </template>
      <template #footer>
        <div class="query__paginator-container--big">
          <Paginator
            v-if="pagination"
            :rows="pagination.pageSize"
            :page-link-size="5"
            :total-records="pagination.totalResults"
            :rows-per-page-options="[10, 20]"
            @page="updatePagination"
          ></Paginator>
        </div>
        <div class="query__paginator-container--small">
          <Paginator
            v-if="pagination"
            :rows="pagination.pageSize"
            :page-link-size="1"
            :total-records="pagination.totalResults"
            @page="updatePagination"
          ></Paginator>
        </div>
      </template>
    </DataView>
  </div>
</template>


<script setup>
import tableConfig from './length-road-type-by-province-table.config';
import {
  DataView,
} from '@sttedra_ui/framework';
import {getAllCCOO} from '@/api/autonomous-communities/autonomous-communities.api';
import {getAllProvinces} from '@/api/province/province.api';
import {ref, onMounted, computed} from 'vue';
import Dropdown from 'primevue/dropdown';
import Panel from 'primevue/panel';
import printData from '@/api/print/printTrackByProvince.api';
import Button from 'primevue/button';
import Chips from 'primevue/chips';
import Paginator from 'primevue/paginator';
const selectedCcaa = ref();
const ccaas = ref([]);
const selectedProvince = ref();
const provinces = ref([]);
const dataView = ref();
let selectedCcaaChip = null;
let selectedProvinceChip = null;
let smallScreen = false;
const {configRef} = tableConfig();
const state$$ = computed(() =>
  !!dataView.value && !!dataView.value.state$$ ? dataView.value.state$$ : null,
);
const pagination = computed(() =>
  !!state$$?.value?.pagination?.pageIndex ? state$$.value.pagination : null,
);

function updateFilters() {
  if (selectedCcaa.value && selectedCcaa.value !== null) {
    dataView.value.state$$.advancedSearch.filters[0].value = selectedCcaa.value.id;
  }
  if (selectedProvince.value && selectedProvince.value !== null) {
    dataView.value.state$$.advancedSearch.filters[1].value = selectedProvince.value.id || null;
  }
}

function updateCcaa() {
  updateFilters();
  dataView.value.state$$.advancedSearch.filters[0].value = selectedCcaa.value.id;
  const filters = dataView.value?.state$$?.advancedSearch?.filters;
  const fCcaa = !!filters && !!filters.length ?
    filters.find((f) => f.field === 'idCcaa') : null;
  if (!!fCcaa) {
    if (selectedCcaa.value && selectedCcaa.value.id === 0) {
      fCcaa.value = null;
      dataView.value.state$$.advancedSearch.filters[0].value = null;
      dataView.value.state$$.advancedSearch.filters[1].value = null;
      selectedCcaaChip = null;
      selectedProvinceChip = null;
      updateProvinceNewCcaa();
    } else if (selectedCcaa.value) {
      fCcaa.value = selectedCcaa.value.id;
      selectedCcaaChip = ['CCAA: ' + selectedCcaa.value.nombre];
      selectedProvinceChip = null;
      updateProvinceNewCcaa();
    }
  }
  dataView.value.state$$.advancedSearch.filters[1].value = null;
  dataView.value.debounceRefreshAndUpdate();
}

function updateProvince() {
  updateFilters();
  const filters = dataView.value?.state$$?.advancedSearch?.filters;
  const fProvince = !!filters && !!filters.length ?
    filters.find((f) => f.field === 'idProvincia') : null;
  if (!!fProvince) {
    if (selectedProvince.value && selectedProvince.value.id === 0) {
      fProvince.value = null;
      dataView.value.state$$.advancedSearch.filters[1].value = null;
      selectedProvinceChip = null;
    } else if (selectedProvince.value) {
      fProvince.value = selectedProvince.value.id;
      selectedProvinceChip = ['Provincia: ' + selectedProvince.value.nombre];
    }
  }
  if (dataView.value.state$$.advancedSearch.filters[0].value === 0) {
    dataView.value.state$$.advancedSearch.filters[0].value = null;
  }
  dataView.value.debounceRefreshAndUpdate();
}

function updateProvinceNewCcaa() {
  provinces.value = [];
  getAllProvinces(selectedCcaa.value.id).then((res) => {
    provinces.value.push({id: 0, nombre: 'Todas las provincias'});
    res.forEach((element) => {
      provinces.value.push(element);
    });
  });
  selectedProvince.value = [];
}

function updatePagination(data) {
  const {rows, page: page0Index} = data;
  const page = page0Index + 1;
  const pagination = dataView.value?.state$$?.pagination;
  if (!!pagination.pageIndex && !!page) {
    pagination.pageIndex = page;
  }
  if (!!pagination.pageSize && !!rows) {
    pagination.pageSize = rows;
  }
  dataView.value.debounceRefreshAndUpdate();
}

function handleResize() {
  if (window.innerWidth <= 768 && !smallScreen) {
    smallScreen = true;
    setTimeout(() => (dataView.value.state$$.viewRef = 'list'), 10);
  }
  if (window.innerWidth > 768 && smallScreen) {
    smallScreen = false;
    setTimeout(() => (dataView.value.state$$.viewRef = 'table'), 10);
  }
}

onMounted(() => {
  getAllCCOO().then((res) => {
    ccaas.value.push({id: 0, nombre: 'Todas las CCAA'});
    res.forEach((element) => {
      ccaas.value.push(element);
    });
  });

  getAllProvinces().then((res) => {
    provinces.value.push({id: 0, nombre: 'Todas las provincias'});
    res.forEach((element) => {
      provinces.value.push(element);
    });
  });
  if (window.screen.width <= 768) {
    setTimeout(() => (dataView.value.state$$.viewRef = 'list'), 10);
  }
  window.addEventListener('resize', handleResize);
});
</script>


<style lang="scss" scoped>
@import '@/styles/panels.scss';
.length-road-type-by-province-container {
  width: 100%;

  & :deep(.p-chip) {
    display: none;
  }
  & :deep(.p-chip-remove-icon) {
    display: none;
  }
  & :deep(.data-view-action.p-button:nth-of-type(1)) {
    display:none
  }
  & :deep(.p-button-secondary) {
    color: #211915 !important;
    background-color: white !important;
    border: none !important;
    &:hover, &:focus, &.p-disabled {
      background-color: #FFDB00 !important;
      border-radius: 15%;
      opacity: 1 !important;
    }
  }
}
.btn-print {
  display: none;
  /* cursor: pointer;
  margin-top: 4%;
  color: #211915;
  & :deep(.pi) {
    font-size: 1.35rem;
  }
  &:hover {
    background-color: #FFDB00 !important;
    border-radius: 15%;
  } */
}
.header-before-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}
.container-chips {
  cursor: text;
  :not(.p-disabled):hover {
    box-shadow: none;
    border-bottom: none;
    border-color: white;
  }
  :active {
    border: none;
  }
}
.prime-chips {
  background: transparent;
  & :deep(.p-chips-token) {
    padding: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  & :deep(.p-chips-input-token) {
    display: none;
  }
  & :deep(.p-chips-token-icon) {
    display: none;
  }
  & :deep(.p-inputtext) {
    background-color: transparent;
    border-bottom: none;
  }
  & :deep(.p-chips-token-label) {
    font-style: italic;
  }
}
.data-view-class {
  & :deep(.p-progressbar-value.p-progressbar-value-animate) {
    background-color: #FFDB00;
  }
  & :deep(.p-dropdown-label.p-inputtext) {
    background-color: transparent;
    font-family: 'Roboto';
    font-size: 14px;
  }
  & :deep(.p-dropdown-label.p-inputtext.p-placeholder) {
    font-family: 'Roboto';
    font-size: 14px;
  }
  & :deep(.data-view__header__after) {
    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }
  & :deep(.data-view__header__after > button) {
    margin-left: 0 !important;
  }
}
.dropdown {
  background: white;
}
.query__paginator-container--small {
  display: block;
  & :deep(.p-highlight) {
    background-color: #FFDB00;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.query__paginator-container--big {
  display: none;
  & :deep(.p-highlight) {
    background-color: #FFDB00;
  }
  @media screen and (min-width: 768px) {
    display: block;
  }
}
</style>
