import {getLengthTrackByProvince} from '@/api/length-road-type/length-road-type.api';
import {getAllProvinces} from '@/api/province/province.api';
import {
  filtersTypeEnum,
  advancedSearchComponentEnum,
  formItemTypeEnum,
  matchModeEnum,
} from '@sttedra_ui/framework';

const DEFAULT_GLOBAL_SEARCH_FIELDS = [
  {field: 'carretera', label: 'Carretera'},
  {field: 'inicio', label: 'Inicio'},
  {field: 'fin', label: 'Fin'},
];

function tableConfig(globalSearchFields = DEFAULT_GLOBAL_SEARCH_FIELDS) {
  const config = {
    api: getLengthTrackByProvince,
    Model: class User {
      constructor(data) {
        Object
          .keys(data)
          .forEach((k) => this[k] = data[k]);
      }
    },
    schema: [
      {
        field: 'carretera',
        label: 'Carretera',
      },
      {
        field: 'provincia',
        label: 'Provincia',
      },
      {
        field: 'pkInicio',
        label: 'PK Inicio',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'pkFin',
        label: 'PK Fin',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'inicio',
        label: 'Inicio',
      },
      {
        field: 'fin',
        label: 'Fin',
      },
      {
        field: 'autopistaPeaje',
        label: 'Autopista',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'autopistaLibreAutovia',
        label: 'Autovía',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'carreteraConvencional',
        label: 'Carretera Convencional',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'multicarril',
        label: 'Multicarril',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'total',
        label: 'Total',
        classObj: ['query__header--align-right'],
      },
    ],
    actions: {
      header: [],
      item: [],
    },
    filters: [
      {
        type: filtersTypeEnum.GLOBAL,
        enabled: true,
        fields: globalSearchFields,
      },
      {
        type: filtersTypeEnum.ADVANCED,
        enabled: true,
        component: advancedSearchComponentEnum.PANEL, // 'sidebar' || 'panel' || 'dialog'
        fields: [
          {
            field: 'idProvincia',
            label: 'Provincia',
            type: formItemTypeEnum.DROPDOWN,
            matchMode: matchModeEnum.EQUALS,
            properties: {
              options: [],
              optionLabel: 'carretera',
              optionValue: 'id',
            },
            events: {
              onMounted: async (updateModel, rest) => {
                const data = await getAllProvinces();
                updateModel((item) => {
                  item.properties.options = data;
                  return item;
                });
              },
            },
          },
        ],
      },
    ],
    messages: {
      loading: 'Cargando resultados',
      empty: 'No hay resultados',
    },
    pagination: {
      availableOptions: [25, 50],
      pageIndex: 1,
      pageSize: 25,
      showAllEnabled: false,
      sortField: 'carretera',
      sortOrder: 'Asc',
    },
    behaviour: {
      useQuery: false,
    },
  };
  return config;
}
export {
  DEFAULT_GLOBAL_SEARCH_FIELDS,
  tableConfig,
};
