const DEFAULT_LOCALE = 'es-ES';

const kmNumberFormat = new Intl.NumberFormat(DEFAULT_LOCALE, {
  style: 'decimal',
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
});
const dateFormat = new Intl.DateTimeFormat(DEFAULT_LOCALE);

export {
  DEFAULT_LOCALE,
  kmNumberFormat,
  dateFormat,
};
