<template>
  <div class="container">
    <div class="container__header">
      <div class="container__header__chart-road-type">
        <LengthRoadTypeRce />
      </div>
      <TabView
        class="container__tabView container__header__road-type-by-ccoo"
        @tab-change="tabChange"
      >
        <TabPanel header="CCAA">
          <LengthRoadTypeByCcaaTable
            v-if="loadTab1"
          />
        </TabPanel>
        <TabPanel header="Provincia">
          <LengthRoadTypeByProvinceTable
            v-if="loadTab2"
          />
        </TabPanel>
        <TabPanel header="Vía">
          <LengthRoadTypeByTrackTable
            v-if="loadTab3"
          />
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import LengthRoadTypeRce from './length-road-type-rce/LengthRoadTypeTable.vue';
import LengthRoadTypeByCcaaTable
  from './length-road-type-by-ccaa-table/LengthRoadTypeByCcaaTable.vue';
import LengthRoadTypeByProvinceTable
  from './length-road-type-by-province-table/LengthRoadTypeByProvinceTable.vue';
import LengthRoadTypeByTrackTable
  from './length-road-type-by-track/LengthRoadTypeByTrackTable.vue';

const loadTab1 = ref(true);
const loadTab2 = ref(false);
const loadTab3 = ref(false);

function tabChange({index}) {
  if (index === 0) {
    loadTab2.value = true;
  }
  if (index === 1) {
    loadTab2.value = true;
  }
  if (index === 2) {
    loadTab3.value = true;
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding: $brand__boxspace--medium * 1.5;
  overflow: auto;
  & :deep(.p-tabview-title) {
    color: #211915;
  }
  &__header {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 3rem;
    &__chart-road-type {
      box-shadow: $brand__boxshadow--bottom;
      border-radius: $brand__borderradius--large;
      display:flex;
      justify-content: center;
      flex-wrap: wrap;

      @media screen and (max-width: $brand__breakpoint--lg) {
        width: 100%;
        margin-bottom: 2rem;
      }
      @media screen and (min-width: $brand__breakpoint--lg) {
        width: 28%
      }
    }

    &__road-type-by-ccoo {
      @media screen and (max-width: $brand__breakpoint--lg) {
        width: 100%
      }
      @media screen and (min-width: $brand__breakpoint--lg) {
        width: 70%
      }

      box-shadow: $brand__boxshadow--bottom;
      border-radius: $brand__borderradius--large;
      padding: $brand__boxspace--medium * 1.5;


    }
  }

  & :deep(.p-tabview .p-tabview-panels) {
    padding-left: 0px;
    padding-right: 0px;
  }
  & :deep(.smart-content__center) {
    padding: 0px;
  }
}
</style>
