import {getLengthRoadTypeByTrack} from '@/api/length-road-type/length-road-type.api';
import {getAllProvinces} from '@/api/province/province.api';
import {getAllCCOO} from '@/api/autonomous-communities/autonomous-communities.api';
import {
  useImmer,
  filtersTypeEnum,
  advancedSearchComponentEnum,
  formItemTypeEnum,
  matchModeEnum,
} from '@sttedra_ui/framework';
import {toRaw} from 'vue';
export default function() {
  const config = {
    title: '',
    api: getLengthRoadTypeByTrack,
    Model: class User {
      constructor(data) {
        Object.keys(data).forEach((k) => (this[k] = data[k]));
      }
    },
    schema: [
      {
        field: 'carretera',
        label: 'Carretera',
      },
      {
        field: 'autopistaPeaje',
        label: 'Autopista',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'autopistaLibreAutovia',
        label: 'Autovía',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'multicarril',
        label: 'Multicarril',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'carreteraConvencional',
        label: 'Carretera Convencional',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'total',
        label: 'Total',
        classObj: ['query__header--align-right'],
      },
    ],
    actions: {
      header: [],
      item: [],
    },
    filters: [
      {
        type: filtersTypeEnum.ADVANCED,
        // enabled: true,
        component: advancedSearchComponentEnum.SIDEBAR, // 'sidebar' || 'panel' || 'dialog'
        fields: [
          {
            field: 'idCcaa',
            label: 'CCAA',
            type: formItemTypeEnum.DROPDOWN,
            matchMode: matchModeEnum.EQUALS,
            properties: {
              options: [],
              optionLabel: 'carretera',
              optionValue: 'id',
            },
            events: {
              onMounted: async (updateModel) => {
                const data = await getAllCCOO();
                updateModel((item) => {
                  item.properties.options = data;
                  return item;
                });
              },
              change: async (...allParams) => {
                const event = allParams[0];
                const methods = allParams[2];
                const data = await getAllProvinces(event.value);
                methods.clearField('idProvincia');
                methods.changeFieldProperties('idProvincia', {
                  options: data,
                  optionLabel: 'carretera',
                  optionValue: 'id',
                });
              },
            },
          },
          {
            field: 'idProvincia',
            label: 'Provincia',
            type: formItemTypeEnum.DROPDOWN,
            matchMode: matchModeEnum.EQUALS,
            properties: {
              options: [],
              optionLabel: 'carretera',
              optionValue: 'id',
            },
            events: {
              onMounted: async (updateModel, rest) => {
                const proxyObject = rest.getValues();
                const rawObject = toRaw(proxyObject);
                const idCcaa = rawObject.idCcaa;
                const data = await getAllProvinces(idCcaa);
                updateModel((item) => {
                  item.properties.options = data;
                  return item;
                });
              },
            },
          },
        ],
      },
    ],
    messages: {
      loading: 'Cargando resultados',
      empty: 'No hay resultados',
    },
    pagination: {
      availableOptions: [10, 25],
      pageIndex: 1,
      pageSize: 10,
      showAllEnabled: false,
      sortField: 'carretera',
      sortOrder: 'Asc',
    },
    behaviour: {
      useQuery: false,
    },
  };

  const {ref: configRef, update: updateConfig} = useImmer(config);
  return {configRef, updateConfig};
}
