import {getSectionsByRoadType} from '@/api/sections-by-road-type/sections-by-road-type.api';
import {getAllProvinces} from '@/api/province/province.api';
import {
  useImmer,
  filtersTypeEnum,
  advancedSearchComponentEnum,
  formItemTypeEnum,
  matchModeEnum,
  operatorEnum,
} from '@sttedra_ui/framework';

export default function() {
  const config = {
    api: getSectionsByRoadType,
    schema: [
      {
        field: 'carretera',
        label: 'Carretera',
      },
      {
        field: 'provincia',
        label: 'Provincia',
      },
      {
        field: 'pkInicio',
        label: 'PK Inicio',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'pkFin',
        label: 'PK Fin',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'inicio',
        label: 'Inicio',
      },
      {
        field: 'fin',
        label: 'Fin',
      },
      {
        field: 'longitud',
        label: 'Longitud',
        classObj: ['query__header--align-right'],
      },
    ],
    actions: {
      header: [],
      item: [],
    },
    filters: [
      {
        type: filtersTypeEnum.ADVANCED,
        // enabled: true,
        component: advancedSearchComponentEnum.PANEL, // 'sidebar' || 'panel' || 'dialog'
        fields: [
          {
            field: 'idTipoVia',
            label: 'Tipo de Vía',
            type: formItemTypeEnum.DROPDOWN,
            matchMode: matchModeEnum.MULTIPLE_EQUALS,
            operator: operatorEnum.AND,
            properties: {
              options: [],
              optionLabel: 'carretera',
              optionValue: 'id',
            },
          },
          {
            field: 'idProvincia',
            label: 'Provincia',
            type: formItemTypeEnum.DROPDOWN,
            matchMode: matchModeEnum.EQUALS,
            properties: {
              options: [],
              optionLabel: 'carretera',
              optionValue: 'id',
            },
            events: {
              onMounted: async (updateModel, rest) => {
                const data = await getAllProvinces();
                updateModel((item) => {
                  item.properties.options = data;
                  return item;
                });
              },
            },
          },
        ],
      },
    ],
    messages: {
      loading: 'Cargando resultados',
      empty: 'No hay resultados',
    },
    pagination: {
      availableOptions: [25, 50],
      pageIndex: 1,
      pageSize: 25,
      showAllEnabled: false,
      sortField: 'carretera',
      sortOrder: 'Asc',
    },
    behaviour: {
      useQuery: false,
    },
  };

  const {ref: configRef, update: updateConfig} = useImmer(config);
  return {configRef, updateConfig};
}
