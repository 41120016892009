import {getRoadNomenclature} from '@/api/road-nomenclature-rce/road-nomenclature-rce.api';
import {
  filtersTypeEnum,
} from '@sttedra_ui/framework';

const DEFAULT_GLOBAL_SEARCH_FIELDS = [
  {field: 'carretera', label: 'Carretera'},
  {field: 'denominacion', label: 'Denominación'},
  {field: 'itinerario', label: 'Itinerario'},
];

function tableConfig(globalSearchFields = DEFAULT_GLOBAL_SEARCH_FIELDS) {
  const config = {
    api: getRoadNomenclature,
    Model: class User {
      constructor(data) {
        Object.keys(data).forEach((k) => (this[k] = data[k]));
      }
    },
    schema: [
      {
        label: 'Carretera',
        field: 'carretera',
      },
      {
        label: 'Denominación',
        field: 'denominacion',
      },
      {
        label: 'Itinerario De Referencia',
        field: 'itinerario',
      },
    ],
    actions: {
      header: [],
      item: [],
    },
    filters: [
      {
        type: filtersTypeEnum.GLOBAL,
        enabled: true,
        fields: globalSearchFields,
      },
    ],
    messages: {
      loading: 'Cargando resultados',
      empty: 'No hay resultados',
    },
    pagination: {
      availableOptions: [25, 50],
      pageIndex: 1,
      pageSize: 25,
      showAllEnabled: false,
      sortField: 'carretera',
      sortOrder: 'Asc',
    },
    behaviour: {
      useQuery: false,
    },
  };
  return config;
}
export {
  DEFAULT_GLOBAL_SEARCH_FIELDS,
  tableConfig,
};
