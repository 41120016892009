/* eslint-disable max-len */
import {
  useAxios,
  useHandleApiError,
} from '@sttedra_ui/framework';
/**
 * @typedef {import("@sttedra_ui/framework").AxiosErrorDataModel} AxiosErrorDataModel
 */

// #region Globals
const CONTEXT_BASE_PATH = 'Export';
const {base} = useAxios();
const {handleApiError} = useHandleApiError();
const timeoutRequestHttp = 30000;
// #endregion
export default function printData(post, name, ccaa, province) {
  const url = post + `/${CONTEXT_BASE_PATH}`;
  let searchModel = {};
  if (!ccaa && !province) {
    searchModel = {
      'filters': null,
      'first': 1,
      'globalSearch': null,
      'pageIndex': 1,
      'pageSize': 15,
      'sortField': 'ccaa',
      'sortOrder': 'Asc',
    };
  }
  if (ccaa && ccaa.id && ccaa.id !== 0) {
    searchModel = {
      'filters': [
        {
          'index': 1,
          'value': String(ccaa.id),
          'field': 'idCcaa',
          'matchMode': 'Equals',
          'operator': 'And',
        },
      ],
    };
  }
  if (province && province.id && province.id !== 0) {
    searchModel = {
      'filters': [
        {
          'index': 1,
          'value': String(province.id),
          'field': 'idProvincia',
          'matchMode': 'Equals',
          'operator': 'And',
        },
      ],
    };
  }
  return base
    .post(url, searchModel, {
      timeout: timeoutRequestHttp,
      responseType: 'arraybuffer',
    })
    .then((res) => {
      download(res.data, name);
    })
    .catch(handleApiError);
}

function download(response, name) {
  const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
  const objectUrl = URL.createObjectURL(blob);

  const downloadLink = document.createElement('a');
  downloadLink.href = objectUrl;
  downloadLink.download = name + '_export.xlsx';

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export {printData};
