import {
  useAxios,
  useHandleApiError,
  SearchApiModel,
  SearchFilterApiModel,
} from '@sttedra_ui/framework';
import SectionsByRoadTypeApiModel from './sections-by-road-type.api-model';
/**
 * @typedef {import("@sttedra_ui/framework").AxiosErrorDataModel} AxiosErrorDataModel
 */

// #region Globals
const CONTEXT_BASE_PATH = 'Longitudes/Carreteras/Tramos';
const {base} = useAxios();
const {handleApiError} = useHandleApiError();
const timeoutRequestHttp = 30000;
// #endregion
const getSectionsByRoadType = (params = {}) => {
  const url = `/${CONTEXT_BASE_PATH}`;
  const searchModel = new SearchApiModel(params);
  if (!searchModel?.filters?.length) { // Añadir filtro de tipo de vía para que salgan resultados
    const defaultFilter = new SearchFilterApiModel({
      field: 'idTipoVia',
      index: 0,
      matchMode: 'MultipleEquals',
      operator: 'And',
      value: '[1001]',
    });
    searchModel.filters = [defaultFilter];
  }
  localStorage.setItem('currentCall', JSON.stringify(searchModel));
  return base
    .post(url, searchModel, {
      timeout: timeoutRequestHttp,
    })
    .then((res) => {
      const dataList = res.data.data.map((obj) => new SectionsByRoadTypeApiModel(obj));
      const dataParsed = [...dataList];
      dataParsed.forEach((element) => {
        element.longitud = String(element.longitud) + ' km';
        element.autopistaLibreAutovia = String(element.autopistaLibreAutovia) + ' km';
        element.multicarril = String(element.multicarril) + ' km';
        element.carreteraConvencional = String(element.carreteraConvencional) + ' km';
        element.total = String(element.total) + ' km';
      });
      return {
        data: [...dataParsed],
        pageSize: params.pageSize || 5,
        pageIndex: params.pageIndex || 1,
        totalResults: res.data.totalResults,
      };
    })
    .catch(handleApiError);
};

export {getSectionsByRoadType};
