import roadNomenclatureRceView
  from '../views/road-nomenclature-rce/RoadNomenclatureRceView.vue';

export default [
  {
    path: '/nomenclatura-carreteras-rce',
    name: 'roadNomenclatureRceView',
    component: roadNomenclatureRceView,
    meta: {
      title: 'Relación de carreteras que forman parte de la RCE',
      bigImage: 'cabecera-carretera-01.jpg',
    },
  },
];
