import SectionsCededView
  from '../views/sections-ceded/SectionsCededView.vue';
export default [
  {
    path: '/tramos-cedidos-rce',
    name: 'SectionsCededView',
    component: SectionsCededView,
    meta: {
      title: 'Tramos de la R.C.E. cedidos',
      bigImage: 'cabecera-carretera-01.jpg',
    },
  },
];
