import {useToNumber, useCheckType, types} from '@sttedra_ui/framework';
import {kmNumberFormat} from '@/api/util-locale';

class LengthRoadTypeApiModel {
  // #region Props
  provincia;
  autopistaLibreAutovia;
  autopistaPeaje;
  carreteraConvencional;
  multicarril;
  total;
  // #endregion

  constructor({
    provincia,
    autopistaLibreAutovia,
    autopistaPeaje,
    carreteraConvencional,
    multicarril,
    total,
  }) {
    this.provincia = this.#getString(provincia);
    const autopistaLibreAutoviaRaw = this.#getNumber(autopistaLibreAutovia);
    this.autopistaLibreAutovia = kmNumberFormat.format(autopistaLibreAutoviaRaw);
    const autopistaPeajeRaw = this.#getNumber(autopistaPeaje);
    this.autopistaPeaje = kmNumberFormat.format(autopistaPeajeRaw);
    const carreteraConvencionalRaw = this.#getNumber(carreteraConvencional);
    this.carreteraConvencional = kmNumberFormat.format(carreteraConvencionalRaw);
    const multicarrilRaw = this.#getNumber(multicarril);
    this.multicarril = kmNumberFormat.format(multicarrilRaw);
    const totalRaw = this.#getNumber(total);
    this.total = kmNumberFormat.format(totalRaw);
  }

  #getNumber(newValue) {
    const number = useToNumber(newValue);
    return number >= 0 ? number : null;
  }
  #getString(newValue) {
    const expectedType = useCheckType(newValue, types.STRING);
    return expectedType ? newValue : null;
  }
}

export default LengthRoadTypeApiModel;
