export const pieDataBase = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#00bb7e', '#F92C00', '#8E8E8E'],
      hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#00bb7e', '#F92C00', '#8E8E8E'],
    },
  ],
};

export const pieOptions = {
  plugins: {
    legend: {
      labels: {
        color: '#495057',
      },
      align: 'start',
    },
  },
};
