import {useToNumber, useCheckType, types} from '@sttedra_ui/framework';

class MasterBaseApiModel {
  // #region Props
  id;
  nombre;
  // #endregion

  constructor({id, nombre}) {
    this.id = this.#getNumber(id);
    this.nombre = this.#getString(nombre);
  }

  // #region Private methods
  #getNumber(newValue) {
    const number = useToNumber(newValue);
    return number >= 0 ? number : null;
  }
  #getString(newValue) {
    const expectedType = useCheckType(newValue, types.STRING);
    return expectedType ? newValue : null;
  }
  // #endregion
}

export default MasterBaseApiModel;
