<template>
  <div class="container">
    <div class="pieChart">
      <h3>Longitud tipo de vía RCE</h3>
      <Chart
        :type="'pie'"
        :data="pieDataRef"
        :options="pieOptionsRef"
      />
    </div>
    <div>
      <DataView
        ref="dataView"
        class="dataView"
        v-bind="configRef"
      ></DataView>
    </div>
  </div>
</template>


<script setup>
import Chart from 'primevue/chart';
import {
  pieDataBase,
  pieOptions,
} from './length-road-type-chart.config';
import {
  ref,
  reactive,
  onMounted,
} from 'vue';
import tableConfig from './length-road-type-table.config';
import {
  DataView,
} from '@sttedra_ui/framework';

// #region Params, Ref, Reactives, Computed
const {configRef} = tableConfig();
const pieDataRef = reactive(pieDataBase);
const pieOptionsRef = ref(pieOptions);
const dataView = ref();
// #endregion

// #region Functions
function setPie() {
  const dataPie = dataView.value.state$$.data;
  dataPie.pop();
  // set the labels
  pieDataRef.labels = dataPie.map((l) => `${l.tipoVia} - ${l.longitud}`);
  // set the data to the pie
  pieDataRef.datasets[0].data = dataPie.map((l) => l.longitudRaw);
}
// #endregion


// #region Effects, Hooks
onMounted(() => {
  let aux = 0;
  const intervalId = setInterval(function() {
    // control - max 10 seconds interval 0,5 seconds
    aux++;
    if (aux++ > 20) {
      clearInterval(intervalId);
    };
    // search data in the dataView table each time
    // eslint-disable-next-line max-len
    if (!!dataView.value && !!dataView.value.state$$.data.length && dataView.value.state$$.data.length !== 0) {
      setPie();
      clearInterval(intervalId);
    }
  }, 500);
});
// #endregion

</script>

<style lang="scss" scoped>
.dataView {
  & :deep(.p-progressbar-value.p-progressbar-value-animate) {
    background-color: #FFDB00;
  }
  & :deep(.p-datatable tbody tr:last-child) {
    font-weight: bold;
  }
}
.container {
  width: 100%;

  & :deep(.paginator) {
    display: none;
  }
  & :deep(.data-view__info) {
    display: none;
  }
  & :deep(.data-view__header) {
    display: none;
  }
}
</style>
