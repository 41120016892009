import {getAllCatalogSections} from '@/api/catalog-sections/catalog-sections.api';
import {getAllRoadTypes} from '@/api/road-type/road-type.api';
import {getAllProvinces} from '@/api/province/province.api';
import {getAllCCOO} from '@/api/autonomous-communities/autonomous-communities.api';
import {
  filtersTypeEnum,
  advancedSearchComponentEnum,
  formItemTypeEnum,
  matchModeEnum,
  operatorEnum,
} from '@sttedra_ui/framework';
import {toRaw} from 'vue';

const DEFAULT_GLOBAL_SEARCH_FIELDS = [
  {field: 'ccaa', label: 'CA'},
  {field: 'provincia', label: 'Provincia'},
  {field: 'carretera', label: 'Carretera'},
  {field: 'inicio', label: 'Inicio'},
  {field: 'fin', label: 'Fin'},
];
function tableConfig(globalSearchFields = DEFAULT_GLOBAL_SEARCH_FIELDS) {
  const config = {
    api: getAllCatalogSections,
    schema: [
      {
        field: 'ccaa',
        label: 'CCAA',
      },
      {
        field: 'provincia',
        label: 'Provincia',
      },
      {
        field: 'carretera',
        label: 'Carretera',
      },
      {
        field: 'pkInicio',
        label: 'PK Inicio',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'pkFin',
        label: 'PK Fin',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'longitud',
        label: 'Longitud',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'inicio',
        label: 'Inicio',
      },
      {
        field: 'fin',
        label: 'Fin',
      },
      {
        field: 'tipoVia',
        label: 'Tipo De Vía',
      },
    ],
    actions: {
      header: [],
      item: [],
    },
    filters: [
      {
        type: filtersTypeEnum.GLOBAL,
        enabled: true,
        fields: globalSearchFields,
      },
      {
        type: filtersTypeEnum.ADVANCED,
        // enabled: true,
        component: advancedSearchComponentEnum.SIDEBAR, // 'sidebar' || 'panel' || 'dialog'
        fields: [
          {
            field: 'idCcaa',
            label: 'CCAA',
            type: formItemTypeEnum.DROPDOWN,
            matchMode: matchModeEnum.EQUALS,
            properties: {
              options: [],
              optionLabel: 'nombre',
              optionValue: 'id',
            },
            events: {
              onMounted: async (updateModel) => {
                const data = await getAllCCOO();
                updateModel((item) => {
                  item.properties.options = data;
                  return item;
                });
              },
              change: async (...allParams) => {
                const event = allParams[0];
                const methods = allParams[2];
                const data = await getAllProvinces(event.value);
                methods.clearField('idProvincia');
                methods.changeFieldProperties('idProvincia', {
                  options: data,
                  optionLabel: 'nombre',
                  optionValue: 'id',
                });
              },
            },
          },
          {
            field: 'idProvincia',
            label: 'Provincia',
            type: formItemTypeEnum.DROPDOWN,
            matchMode: matchModeEnum.EQUALS,
            properties: {
              options: [],
              optionLabel: 'nombre',
              optionValue: 'id',
            },
            events: {
              onMounted: async (updateModel, rest) => {
                const proxyObject = rest.getValues();
                const rawObject = toRaw(proxyObject);
                const idCcaa = rawObject.idCcaa;
                const data = await getAllProvinces(idCcaa);
                updateModel((item) => {
                  item.properties.options = data;
                  return item;
                });
              },
            },
          },
          {
            field: 'idTipoVia',
            label: 'Tipo Vía',
            type: formItemTypeEnum.DROPDOWN,
            matchMode: matchModeEnum.MULTIPLE_EQUALS,
            operator: operatorEnum.AND,
            properties: {
              options: [],
              optionLabel: 'nombre',
              optionValue: 'id',
            },
            events: {
              onMounted: async (updateModel) => {
                const data = await getAllRoadTypes();
                updateModel((item) => {
                  item.properties.options = data;
                  return item;
                });
              },
            },
          },
        ],
      },
    ],
    messages: {
      loading: 'Cargando resultados',
      empty: 'No hay resultados',
    },
    pagination: {
      availableOptions: [25, 50],
      pageIndex: 1,
      pageSize: 25,
      showAllEnabled: false,
    },
    behaviour: {
      useQuery: false,
    },
  };

  return config;
}
export {
  DEFAULT_GLOBAL_SEARCH_FIELDS,
  tableConfig,
};
