import {getLengthTrackByRoadType} from '@/api/length-road-type/length-road-type.api';
import {
  useImmer,
} from '@sttedra_ui/framework';

export default function() {
  const config = {
    title: '',
    api: getLengthTrackByRoadType,
    Model: class User {
      constructor(data) {
        Object
          .keys(data)
          .forEach((k) => this[k] = data[k]);
      }
    },
    schema: [
      {
        field: 'tipoVia',
        label: 'Tipo de vía',
        sortable: false,
      },
      {
        field: 'longitud',
        label: 'Longitud',
        classObj: ['query__header--align-right'],
        sortable: false,
      },
    ],
    actions: {
      header: [],
      item: [],
    },
    filters: [],
    messages: {
      loading: 'Cargando resultados',
      empty: 'No hay resultados',
    },
    pagination: {
      availableOptions: [4],
      pageIndex: 1,
      pageSize: 4,
      showAllEnabled: false,
      sortField: 'tipoVia',
      sortOrder: 'Asc',
    },
    behaviour: {
      useQuery: false,
    },
  };

  const {ref: configRef, update: updateConfig} = useImmer(config);
  return {configRef, updateConfig};
}
