import lengthTrackByRoadTypeView
  from '../views/lenght-track-by-road-type/LengthTrackByRoadTypeView.vue';

export default [
  {
    path: '/longitud-carreteras-tipo-via',
    name: 'lengthTrackByRoadTypeView',
    component: lengthTrackByRoadTypeView,
    meta: {
      title: 'Longitud de carreteras de la RCE por tipo de vía',
      bigImage: 'cabecera-carretera-01.jpg',
    },
  },
];
