<template>
  <div class="length-track-by-road-type-container">
    <DataView
      v-bind="configRef"
      ref="dataView"
      class="data-view"
    >
      <template #header__before>
        <div class="header-before-container">
          <Panel
            header="Búsqueda"
            class="panel"
          >
            <ComboSearch
              :search-fields="DEFAULT_GLOBAL_SEARCH_FIELDS"
              class="combo-search-class"
              @update:model-value="updateSearch"
            />
          </Panel>
        </div>
      </template>
      <template #header__after>
        <div>
          <Button
            class="btn-print"
            icon="pi pi-print"
            text
            @click="printData('/CatalogoTramos', 'Catálogo de Tramos')"
          >
          </Button>
        </div>
      </template>
      <template #footer>
        <div class="query__paginator-container--big">
          <Paginator
            v-if="pagination"
            :rows="pagination.pageSize"
            :page-link-size="5"
            :total-records="pagination.totalResults"
            :rows-per-page-options="[25, 50]"
            @page="updatePagination"
          ></Paginator>
        </div>
        <div class="query__paginator-container--small">
          <Paginator
            v-if="pagination"
            :rows="pagination.pageSize"
            :page-link-size="1"
            :total-records="pagination.totalResults"
            @page="updatePagination"
          ></Paginator>
        </div>
      </template>
    </DataView>
  </div>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue';
import {DataView} from '@sttedra_ui/framework';
import {tableConfig, DEFAULT_GLOBAL_SEARCH_FIELDS} from './length-track-by-road-type.view-config';
import printData from '@/api/print/print.api';
import Button from 'primevue/button';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import ComboSearch from '@/components/combo-search/ComboSearch';

const globalSearchFields = ref(DEFAULT_GLOBAL_SEARCH_FIELDS);
const configRef = computed(() => {
  return tableConfig(globalSearchFields.value);
});
const state$$ = computed(() =>
  !!dataView.value && !!dataView.value.state$$ ? dataView.value.state$$ : null,
);
const pagination = computed(() =>
  !!state$$?.value?.pagination?.pageIndex ? state$$.value.pagination : null,
);
const dataView = ref();
let timeout;
let smallScreen = false;

function updateSearch(newValue) {
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(() => {
    const {search, searchFields} = newValue;
    if (!!search) {
      globalSearchFields.value = searchFields;
    }
    dataView.value.state$$.globalSearch.valueRef = search;
  }, 500);
}

function updatePagination(data) {
  const {rows, page: page0Index} = data;
  const page = page0Index + 1;
  const pagination = dataView.value?.state$$?.pagination;
  if (!!pagination.pageIndex && !!page) {
    pagination.pageIndex = page;
  }
  if (!!pagination.pageSize && !!rows) {
    pagination.pageSize = rows;
  }
  dataView.value.debounceRefreshAndUpdate();
}

function handleResize() {
  if (window.innerWidth <= 768 && !smallScreen) {
    smallScreen = true;
    setTimeout(() => (dataView.value.state$$.viewRef = 'list'), 10);
  }
  if (window.innerWidth > 768 && smallScreen) {
    smallScreen = false;
    setTimeout(() => (dataView.value.state$$.viewRef = 'table'), 10);
  }
}

onMounted(() => {
  if (window.screen.width <= 768) {
    setTimeout(() => (dataView.value.state$$.viewRef = 'list'), 10);
  }
  window.addEventListener('resize', handleResize);
});
</script>

<style lang="scss" scoped>
@import '@/styles/panels.scss';
.length-track-by-road-type-container {
  width: 100%;
  & :deep(.data-view-header-after.p-button:nth-of-type(1)) {
    display:none
  }
}
.header-before-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}
.btn-print {
  display: none;
}
.data-view{
  & :deep(.data-view__header) {
    margin-top: 1vh;
  }
  & :deep(.data-view__data .p-datatable) {
    height: 60vh;
    @media screen and (max-height: 900px) {
      height: 55vh;
    }
    @media screen and (max-height: 800px) {
      height: 50vh;
    }
    @media screen and (max-height: 700px) {
      height: 43vh;
    }
    @media screen and (max-height: 600px) {
      height: 35vh;
    }
    @media screen and (max-height: 500px) {
      height: 26vh;
    }
    @media screen and (max-height: 450px) {
      height: 18vh;
    }
  }
  & :deep(.p-dropdown-label.p-inputtext) {
    background-color: transparent;
    font-family: 'Roboto';
    font-size: 14px;
  }
  & :deep(.p-dropdown-label.p-inputtext.p-placeholder) {
    font-family: 'Roboto';
    font-size: 14px;
  }
  & :deep(.p-progressbar-value.p-progressbar-value-animate) {
    background-color: #FFDB00;
  }

  & :deep(.p-inputtext.p-component.data-view__input) {
    display: none;
  }
  & :deep(.p-button.p-component.data-view__input__close-button) {
    display: none;
  }
  & :deep(.data-view__header__after) {
    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }
  & :deep(.data-view__header__after > button) {
    margin-left: 0 !important;
  }
  & :deep(.p-button-secondary) {
    color: #211915 !important;
    background-color: white !important;
    border: none !important;
    &:hover, &:focus, &.p-disabled {
      background-color: #FFDB00 !important;
      border-radius: 15%;
      opacity: 1 !important;
    }
  }
}
.inputSearch {
  background-color: white;
  margin-left: 0.7rem !important;
  border-bottom: 2px solid #211915;
  &:hover {
    border-bottom: 2px solid #FFDB00;
  }
  &:focus {
    border-bottom: 2px solid #FFDB00;
  }
}
.combo-search-class {
  background-color: transparent;
  & :deep(.input-search) {
    font-size: 14px;
  }
  & :deep(.input-search::placeholder) {
    font-size: 14px;
    color: rgb(70, 70, 70);
  }
  & :deep(.p-inputtext) {
    &:hover, &:focus {
      border-bottom: 2px solid #FFDB00;
    }
  }
  & :deep(.p-multiselect) {
    background-color: transparent;
  }
  & :deep(.p-multiselect-label) {
    color: rgb(70, 70, 70);
    font-size: 14px;
  }
  & :deep(.p-multiselect-trigger-icon) {
    color: rgb(70, 70, 70);
  }
}
.query__paginator-container--small {
  display: block;
  & :deep(.p-highlight) {
    background-color: #FFDB00;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.query__paginator-container--big {
  display: none;
  & :deep(.p-highlight) {
    background-color: #FFDB00;
  }
  @media screen and (min-width: 768px) {
    display: block;
  }
}
</style>
