import ProposedFutureRoadsView
  from '../views/proposed-future-roads/ProposedFutureRoadsView.vue';

export default [
  {
    path: '/propuestas-futuras-carreteras',
    name: 'ProposedFutureRoadsView',
    component: ProposedFutureRoadsView,
    meta: {
      title: 'Nomenclatura propuesta en Anexo II para carreteras que todavía no están en servicio',
      bigImage: 'cabecera-carretera-01.jpg',
    },
  },
];
