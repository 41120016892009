import {SearchApiModel} from '@sttedra_ui/framework';
import {
  useAxios,
  useHandleApiError,
} from '@sttedra_ui/framework';
import LengthRoadTypeSummaryApiModel from './length-road-type-summary.api-model';
import LengthRoadTypeApiModel from './length-road-type.api-model';
import LengthRoadTypeByCcaaApiModel from './length-road-type-by-ccaa.api-model';
import LengthRoadTypeByProvinceApiModel from './length-road-type-by-province.api-model';

const CONTEXT_BASE_PATH_1 = 'Longitudes/TiposVias';
const CONTEXT_BASE_PATH_2 = 'Longitudes/Ccaas';
const CONTEXT_BASE_PATH_3 = 'Longitudes/Provincias';
const CONTEXT_BASE_PATH_4 = 'Longitudes/Carreteras';
const CONTEXT_BASE_PATH_5 = 'Longitudes/Carreteras/TiposVias';
const CONTEXT_BASE_PATH_6 = 'Longitudes/Provincias/Carreteras';
const timeoutRequestHttp = 30000;
// const timeoutPause = 3000;
const {base} = useAxios();
const {handleApiError} = useHandleApiError();

const getLengthTrackByRoadType = (params = {}) => {
  const url = `/${CONTEXT_BASE_PATH_1}`;
  const searchModel = new SearchApiModel(params);
  localStorage.setItem('currentCall', JSON.stringify(searchModel));
  return base
    .post(url, searchModel, {
      timeout: timeoutRequestHttp,
    })
    .then((res) => {
      // add total
      let totalKm = 0;
      res.data.data.forEach((element) => {
        totalKm = totalKm + element.longitud;
      });
      const total = {
        id: 9999,
        longitud: String(totalKm) + ' km',
        longitudRaw: totalKm,
        tipoVia: 'Total',
      };
      res.data.data.push(total);

      const dataList = res.data.data.map((obj) => new LengthRoadTypeSummaryApiModel(obj));
      dataList.forEach((element) => {
        element.longitud = String(element.longitud) + ' km';
      });
      return {
        data: dataList,
        pageSize: params.pageSize || 5,
        pageIndex: params.pageIndex || 1,
        totalResults: res.data.totalResults,
      };
    })
    .catch(handleApiError);
};

const getLengthRoadTypeByCCAA = (ccooIdToFilter = []) => (params = {}) => {
  const url = `/${CONTEXT_BASE_PATH_2}`;
  const searchModel = new SearchApiModel(params);
  localStorage.setItem('currentCall', JSON.stringify(searchModel));
  return base
    .post(url, searchModel, {
      timeout: timeoutRequestHttp,
    })
    .then((res) => {
      const dataList = res.data.data.map((obj) => new LengthRoadTypeByCcaaApiModel(obj));

      const filteredData = ccooIdToFilter.length ?
        dataList.filter((l) => {
          const selectedValues = ccooIdToFilter;
          return selectedValues.some((e) => e === l.idCcaa);
        }) :
        dataList;
      filteredData.forEach((element) => {
        element.autopistaPeaje = String(element.autopistaPeaje) + ' km';
        element.autopistaLibreAutovia = String(element.autopistaLibreAutovia) + ' km';
        element.multicarril = String(element.multicarril) + ' km';
        element.carreteraConvencional = String(element.carreteraConvencional) + ' km';
        element.total = String(element.total) + ' km';
      });
      return Promise.resolve(filteredData).then((data) => {
        return {
          data: dataList,
          pageSize: params.pageSize || 5,
          pageIndex: params.pageIndex || 1,
          totalResults: res.data.totalResults,
        };
      });
    })
    .catch(handleApiError);
};

const getLengthRoadTypeByProvince = (params = {}) => {
  const url = `/${CONTEXT_BASE_PATH_3}`;
  const searchModel = new SearchApiModel(params);
  localStorage.setItem('currentCall', JSON.stringify(searchModel));
  return base
    .post(url, searchModel, {
      timeout: timeoutRequestHttp,
    })
    .then((res) => {
      const dataList = res.data.data.map((obj) => new LengthRoadTypeByProvinceApiModel(obj));
      dataList.forEach((element) => {
        element.autopistaPeaje = String(element.autopistaPeaje) + ' km';
        element.autopistaLibreAutovia = String(element.autopistaLibreAutovia) + ' km';
        element.multicarril = String(element.multicarril) + ' km';
        element.carreteraConvencional = String(element.carreteraConvencional) + ' km';
        element.total = String(element.total) + ' km';
      });
      return {
        data: dataList,
        pageSize: params.pageSize || 5,
        pageIndex: params.pageIndex || 1,
        totalResults: res.data.totalResults,
      };
    })
    .catch(handleApiError);
};

const getLengthRoadTypeByTrack = (params = {}) => {
  const url = `/${CONTEXT_BASE_PATH_4}`;
  const searchModel = new SearchApiModel(params);
  localStorage.setItem('currentCall', JSON.stringify(searchModel));
  return base
    .post(url, searchModel, {
      timeout: timeoutRequestHttp,
    })
    .then((res) => {
      const dataList = res.data.data.map((obj) => new LengthRoadTypeApiModel(obj));
      dataList.forEach((element) => {
        element.autopistaPeaje = String(element.autopistaPeaje) + ' km';
        element.autopistaLibreAutovia = String(element.autopistaLibreAutovia) + ' km';
        element.multicarril = String(element.multicarril) + ' km';
        element.carreteraConvencional = String(element.carreteraConvencional) + ' km';
        element.total = String(element.total) + ' km';
      });
      return {
        data: dataList,
        pageSize: params.pageSize,
        pageIndex: params.pageIndex,
        totalResults: res.data.totalResults,
      };
    })
    .catch(handleApiError);
};

const getLengthTrackByRoadTypeAndProvince = (params = {}) => {
  const url = `/${CONTEXT_BASE_PATH_5}`;
  const searchModel = new SearchApiModel(params);
  localStorage.setItem('currentCall', JSON.stringify(searchModel));
  return base
    .post(url, searchModel, {
      timeout: timeoutRequestHttp,
    })
    .then((res) => {
      const dataList = res.data.data.map((obj) => new LengthRoadTypeApiModel(obj));
      const dataParsed = [...dataList];
      dataParsed.forEach((element) => {
        element.autopistaPeaje = String(element.autopistaPeaje) + ' km';
        element.autopistaLibreAutovia = String(element.autopistaLibreAutovia) + ' km';
        element.multicarril = String(element.multicarril) + ' km';
        element.carreteraConvencional = String(element.carreteraConvencional) + ' km';
        element.total = String(element.total) + ' km';
      });
      return {
        data: [...dataParsed],
        pageSize: params.pageSize || 5,
        pageIndex: params.pageIndex || 1,
        totalResults: res.data.totalResults,
      };
    })
    .catch(handleApiError);
};

const getLengthTrackByProvince = (params = {}) => {
  const url = `/${CONTEXT_BASE_PATH_6}`;
  const searchModel = new SearchApiModel(params);
  localStorage.setItem('currentCall', JSON.stringify(searchModel));
  return base
    .post(url, searchModel, {
      timeout: timeoutRequestHttp,
    })
    .then((res) => {
      const dataList = res.data.data.map((obj) => new LengthRoadTypeApiModel(obj));
      const dataParsed = [...dataList];
      dataParsed.forEach((element) => {
        element.autopistaPeaje = String(element.autopistaPeaje) + ' km';
        element.autopistaLibreAutovia = String(element.autopistaLibreAutovia) + ' km';
        element.multicarril = String(element.multicarril) + ' km';
        element.carreteraConvencional = String(element.carreteraConvencional) + ' km';
        element.total = String(element.total) + ' km';
      });
      return {
        data: [...dataParsed],
        pageSize: params.pageSize || 5,
        pageIndex: params.pageIndex || 1,
        totalResults: res.data.totalResults,
      };
    })
    .catch(handleApiError);
};

export {
  getLengthTrackByRoadType,
  getLengthRoadTypeByCCAA,
  getLengthRoadTypeByProvince,
  getLengthRoadTypeByTrack,
  getLengthTrackByRoadTypeAndProvince,
  getLengthTrackByProvince,
};
