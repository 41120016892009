import Home from '../views/home/Home.vue';
export default [
  {
    path: '/',
    name: 'CatalogSectionsView',
    component: Home,
    meta: {
      title: 'Tramificación',
      bigImage: 'cabecera-carretera-01.jpg',
    },
  },
];
