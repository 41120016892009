import {
  filtersTypeEnum,
  matchModeEnum,
  advancedSearchComponentEnum,
  formItemTypeEnum,
} from '@sttedra_ui/framework';
import {getSectionsInaugurated}
  from '@/api/sections-inaugurated/sections-inaugurated.api';
import {filterDateToLocaleDateString} from '../util-locale';

const DEFAULT_GLOBAL_SEARCH_FIELDS = [
  {field: 'carretera', label: 'Carretera'},
  {field: 'clave', label: 'Clave'},
  {field: 'tramo', label: 'Tramo'},
];

function tableConfig(globalSearchFields = DEFAULT_GLOBAL_SEARCH_FIELDS) {
  const config = {
    api: getSectionsInaugurated,
    Model: class User {
      constructor(data) {
        Object.keys(data).forEach((k) => (this[k] = data[k]));
      }
    },
    schema: [
      {
        field: 'carretera',
        label: 'Carretera',
      },
      {
        field: 'clave',
        label: 'Clave',
      },
      {
        field: 'tramo',
        label: 'Tramo',
      },
      {
        field: 'longitud',
        label: 'Longitud',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'tipoVia',
        label: 'Tipo',
      },
      {
        field: 'pkinicioT',
        label: 'PK Inicio',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'pkfinT',
        label: 'PK Fin',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'ccaa',
        label: 'C. Autónoma',
      },
      {
        field: 'fechaServicio',
        label: 'Fecha De Puesta En Servicio',
      },
      {
        field: 'incrementoRed',
        label: 'Incremento De Red',
      },
    ],
    actions: {
      header: [],
      item: [],
    },
    filters: [
      {
        type: filtersTypeEnum.GLOBAL,
        enabled: true,
        fields: globalSearchFields,
      },
      {
        type: filtersTypeEnum.ADVANCED,
        component: advancedSearchComponentEnum.SIDEBAR, // 'sidebar' || 'panel' || 'dialog'
        fields: [
          {
            field: 'fechaServicio',
            label: 'Inicio Fecha De Puesta En Servicio',
            type: formItemTypeEnum.INPUT,
            matchMode: matchModeEnum.GREATER_OR_EQUAL_THAN,
            custom: {
              chip: filterDateToLocaleDateString,
            },
          },
          {
            field: 'fechaServicioFin',
            label: 'Fin Fecha De Puesta En Servicio',
            type: formItemTypeEnum.INPUT,
            matchMode: matchModeEnum.LESS_OR_EQUAL_THAN,
            custom: {
              chip: filterDateToLocaleDateString,
            },
          },
        ],
      },
    ],
    messages: {
      loading: 'Cargando resultados',
      empty: 'No hay resultados',
    },
    pagination: {
      availableOptions: [25, 50],
      pageIndex: 1,
      pageSize: 25,
      showAllEnabled: false,
      sortField: 'fechaServicio',
      sortOrder: 'Desc',
    },
    behaviour: {
      useQuery: false,
    },
  };
  return config;
}
export {
  DEFAULT_GLOBAL_SEARCH_FIELDS,
  tableConfig,
};
