import {
  useAxios,
  useHandleApiError,
} from '@sttedra_ui/framework';
/**
 * @typedef {import("@sttedra_ui/framework").AxiosErrorDataModel} AxiosErrorDataModel
 */

// #region Globals
const CONTEXT_BASE_PATH = 'Export';
const {base} = useAxios();
const {handleApiError} = useHandleApiError();
const timeoutRequestHttp = 30000;
// #endregion
export default function printData(post) {
  const url = post + `/${CONTEXT_BASE_PATH}`;
  return base
    .post(url, {
      timeout: timeoutRequestHttp,
      responseType: 'arraybuffer',
    })
    .then((res) => {
      window.open(res.data.url);
    })
    .catch(handleApiError);
};

export {printData};
