import {getLengthRoadTypeByCCAA} from '@/api/length-road-type/length-road-type.api';

import {
  useImmer,
} from '@sttedra_ui/framework';

export default function(ccooIdToFilter) {
  const config = {
    api: getLengthRoadTypeByCCAA(ccooIdToFilter),
    Model: class User {
      constructor(data) {
        Object.keys(data).forEach((k) => (this[k] = data[k]));
      }
    },
    schema: [
      {
        field: 'ccaa',
        label: 'CCAA',
      },
      {
        field: 'autopistaPeaje',
        label: 'Autopista',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'autopistaLibreAutovia',
        label: 'Autovía',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'multicarril',
        label: 'Multicarril',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'carreteraConvencional',
        label: 'Carretera Convencional',
        classObj: ['query__header--align-right'],
      },
      {
        field: 'total',
        label: 'Total',
        classObj: ['query__header--align-right'],
      },
    ],
    filters: [],
    messages: {
      loading: 'Cargando resultados',
      empty: 'No hay resultados',
    },
    pagination: {
      availableOptions: [20],
      pageIndex: 1,
      pageSize: 20,
      showAllEnabled: false,
      sortField: 'ccaa',
      sortOrder: 'Asc',
    },
    behaviour: {
      useQuery: false,
    },
  };

  const {ref: configRef, update: updateConfig} = useImmer(config);
  return {configRef, updateConfig};
}
