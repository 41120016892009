import {useToNumber, useCheckType, types} from '@sttedra_ui/framework';
import {kmNumberFormat} from '../util-locale';

class LengthRoadTypeApiModel {
  // #region Props
  carretera;
  provincia;
  pkInicio;
  pkFin;
  inicio;
  fin;
  tiposVias;
  autopistaLibreAutovia;
  autopistaPeaje;
  carreteraConvencional;
  multicarril;
  total;
  // #endregion

  constructor({
    carretera,
    provincia,
    pkInicio,
    pkFin,
    inicio,
    fin,
    autopistaLibreAutovia,
    autopistaPeaje,
    carreteraConvencional,
    multicarril,
    total,
  }) {
    this.carretera = this.#getString(carretera);
    this.provincia = this.#getString(provincia);
    const pkInicioRaw = this.#getNumber(pkInicio);
    this.pkInicio = kmNumberFormat.format(pkInicioRaw);
    const pkFinRaw = this.#getNumber(pkFin);
    this.pkFin = kmNumberFormat.format(pkFinRaw);
    this.inicio = this.#getString(inicio);
    this.fin = this.#getString(fin);
    const autopistaLibreAutoviaRaw = this.#getNumber(autopistaLibreAutovia);
    this.autopistaLibreAutovia = kmNumberFormat.format(autopistaLibreAutoviaRaw);
    const autopistaPeajeRaw = this.#getNumber(autopistaPeaje);
    this.autopistaPeaje = kmNumberFormat.format(autopistaPeajeRaw);
    const carreteraConvencionalRaw = this.#getNumber(carreteraConvencional);
    this.carreteraConvencional = kmNumberFormat.format(carreteraConvencionalRaw);
    const multicarrilRaw = this.#getNumber(multicarril);
    this.multicarril = kmNumberFormat.format(multicarrilRaw);
    const totalRaw = this.#getNumber(total);
    this.total = kmNumberFormat.format(totalRaw);
  }

  #getNumber(newValue) {
    const number = useToNumber(newValue);
    return number >= 0 ? number : null;
  }
  #getString(newValue) {
    const expectedType = useCheckType(newValue, types.STRING);
    return expectedType ? newValue : null;
  }
}

export default LengthRoadTypeApiModel;
