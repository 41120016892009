import {
  useAxios,
  useHandleApiError,
  SearchApiModel,
} from '@sttedra_ui/framework';
import CatalogSectionsApiModel from './catalog-sections.api-model';
/**
 * @typedef {import("@sttedra_ui/framework").AxiosErrorDataModel} AxiosErrorDataModel
 */

// #region Globals
const CONTEXT_BASE_PATH = 'CatalogoTramos';
const {base} = useAxios();
const {handleApiError} = useHandleApiError();
const timeoutRequestHttp = 30000;
// #endregion

const getAllCatalogSections = (params = {}) => {
  const url = `/${CONTEXT_BASE_PATH}`;
  const searchModel = new SearchApiModel(params);
  localStorage.setItem('currentCall', JSON.stringify(searchModel));
  return base
    .post(url, searchModel, {
      timeout: timeoutRequestHttp,
    })
    .then((res) => {
      const dataList = res.data.data.map((obj) => new CatalogSectionsApiModel(obj));
      const dataParsed = [...dataList];
      dataParsed.forEach((element) => {
        element.longitud = String(element.longitud) + ' km';
      });
      return {
        data: [...dataParsed],
        pageSize: params.pageSize || 5,
        pageIndex: params.pageIndex || 1,
        totalResults: res.data.totalResults,
      };
    })
    .catch(handleApiError);
};

export {getAllCatalogSections};
