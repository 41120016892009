<template>
  <div class="mobile-menu__container">
    <i
      v-if="props.onlyBars"
      class="mobile-menu__icon pi pi-bars"
      @click="emit('toggle-sidebar')"
    ></i>

    <div
      v-if="!props.onlyBars"
      class="mobile-menu__list-container"
    >
      <div
        v-for="r in menuRoutes"
        :key="r.name"
        class="mobile-menu__item"
      >
        <span
          v-show="!!r.icon"
          :class="classIcon(r.icon)"
        >{{ r.icon }}</span>
        <a
          @click.stop="clickRoute(r)"
        >
          {{ r.title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import {MAIN_NAV_ROUTES} from '@/pages/_root/app.view-model';
// #region Props, Emits
const props = defineProps({
  onlyBars: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['toggle-sidebar', 'click-route']);
// #endregion

// #region Params, Ref, Reactives, Computed
const menuRoutes = ref(MAIN_NAV_ROUTES);
// #endregion

// #region Functions
function classIcon(iconName) {
  const baseClass = 'mobile-menu__item-icon';
  return baseClass + ' material-icons';
}
async function clickRoute(r) {
  emit('click-route', r);
}
// #endregion

// #region Effects, Hooks

// #endregion

// #region Provide, Expose

// #endregion
</script>

<style lang="scss" scoped>
.mobile-menu {
  &__list-container {
    margin: 4px;
    box-shadow: 2px 2px 12px rgba(0,0,0,0.15);
  }
  &__icon {
    display: inline-flex;
    background-color: lightgray;
    font-size: 1.5rem;
    border: none;
    border-radius: 5px;
    color: black;
  }
  &__item {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    padding: 1rem;
    border-bottom: 1px solid #f2f2f2;
    &-icon {
      margin-right: 0.5rem;
    }
  }
}
</style>
