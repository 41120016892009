<!-- eslint-disable max-len -->
<template>
  <div class="container">
    <DataView
      v-bind="configRef"
      ref="dataView"
      class="data-view-class"
    >
      <template #header__before>
        <div class="header-before-container">
          <Panel
            header="Filtros"
            class="panel"
          >
            <MultiSelect
              v-model="selectedRoadType"
              :options="roadTypes"
              option-label="nombre"
              placeholder="Tipo de Vía"
              class="w-full md:w-20rem m-2 dropdown"
              @update:model-value="updateRoadType"
            />
            <Dropdown
              v-model="selectedProvince"
              :options="provinces"
              option-label="nombre"
              placeholder="Provincia"
              class="w-full md:w-20rem m-2 dropdown"
              @update:model-value="updateProvince"
            />
          </Panel>
        </div>
      </template>
      <template #header__after>
        <div>
          <Button
            class="btn-print"
            icon="pi pi-print"
            text
            @click="printData('/CatalogoTramos', 'Catálogo de Tramos')"
          >
          </Button>
        </div>
      </template>
      <template #info>
        <div class="data-view__info">
          <div class="container-chips">
            <Chips
              v-if="!!selectedRoadTypeChip"
              v-model="selectedRoadTypeChip"
              class="prime-chips"
            />
            <Chips
              v-if="!!selectedProvinceChip"
              v-model="selectedProvinceChip"
              class="prime-chips"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="query__paginator-container--big">
          <Paginator
            v-if="pagination"
            :rows="pagination.pageSize"
            :page-link-size="5"
            :total-records="pagination.totalResults"
            :rows-per-page-options="[25, 50]"
            @page="updatePagination"
          ></Paginator>
        </div>
        <div class="query__paginator-container--small">
          <Paginator
            v-if="pagination"
            :rows="pagination.pageSize"
            :page-link-size="1"
            :total-records="pagination.totalResults"
            @page="updatePagination"
          ></Paginator>
        </div>
      </template>
    </DataView>
  </div>
</template>

<script setup>
import {DataView} from '@sttedra_ui/framework';
import tableConfig from './provincial-sections-by-road-type.view-config';
const {configRef} = tableConfig();
import {getAllRoadTypes} from '@/api/road-type/road-type.api';
import {getAllProvinces} from '@/api/province/province.api';
import {ref, onMounted, computed} from 'vue';
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';
import Panel from 'primevue/panel';
import printData from '@/api/print/print.api';
import Button from 'primevue/button';
import Chips from 'primevue/chips';
import Paginator from 'primevue/paginator';
const state$$ = computed(() =>
  !!dataView.value && !!dataView.value.state$$ ? dataView.value.state$$ : null,
);
const pagination = computed(() =>
  !!state$$?.value?.pagination?.pageIndex ? state$$.value.pagination : null,
);
const selectedRoadType = ref();
const roadTypes = ref([]);
const selectedProvince = ref();
const provinces = ref([]);
const selectedRoadTypeChip = ref(null);
const selectedProvinceChip = ref(null);
let smallScreen = false;

const dataView = ref();

function updateRoadType() {
  const filters = dataView.value?.state$$?.advancedSearch?.filters;
  const fRoadType = !!filters && !!filters.length ?
    filters.find((f) => f.field === 'idTipoVia') : null;
  if (!!fRoadType) {
    if (!selectedRoadType?.value?.length) {
      selectedRoadType.value = [{
        id: 1001,
        nombre: 'Autopista de peaje',
      }];
    }
    const stringNombre = selectedRoadType.value.map((el) => el.nombre).join(' - ');
    const stringIdJson = JSON.stringify(selectedRoadType.value.map((el) => el.id));
    fRoadType.value = stringIdJson;
    selectedRoadTypeChip.value = ['Tipo de vía: ' + stringNombre];
  }

  dataView.value.debounceRefreshAndUpdate();
}

function updateProvince() {
  updateRoadType();
  dataView.value.state$$.advancedSearch.filters[1].value = selectedProvince.value.id;
  const filters = dataView.value?.state$$?.advancedSearch?.filters;
  const fProvince = !!filters && !!filters.length ?
    filters.find((f) => f.field === 'idProvincia') : null;
  if (!!fProvince) {
    if (selectedProvince.value && selectedProvince.value.id === 0) {
      fProvince.value = null;
      selectedProvinceChip.value = null;
    } else {
      fProvince.value = selectedProvince.value.id;
      selectedProvinceChip.value = ['Provincia: ' + selectedProvince.value.nombre];
    }
  }
  dataView.value.debounceRefreshAndUpdate();
}

function updatePagination(data) {
  const {rows, page: page0Index} = data;
  const page = page0Index + 1;
  const pagination = dataView.value?.state$$?.pagination;
  if (!!pagination.pageIndex && !!page) {
    pagination.pageIndex = page;
  }
  if (!!pagination.pageSize && !!rows) {
    pagination.pageSize = rows;
  }
  dataView.value.debounceRefreshAndUpdate();
}

function handleResize() {
  if (window.innerWidth <= 768 && !smallScreen) {
    smallScreen = true;
    setTimeout(() => (dataView.value.state$$.viewRef = 'list'), 10);
  }
  if (window.innerWidth > 768 && smallScreen) {
    smallScreen = false;
    setTimeout(() => (dataView.value.state$$.viewRef = 'table'), 10);
  }
}

onMounted(async () => {
  const roadTypesAll = await getAllRoadTypes();
  roadTypesAll.forEach((el) => {
    roadTypes.value.push(el);
  });
  selectedRoadType.value = [roadTypes.value[1]]; // Autopista de peaje
  const provincesAll = await getAllProvinces();
  provinces.value.push({id: 0, nombre: 'Todas las provincias'});
  provincesAll.forEach((el) => {
    provinces.value.push(el);
  });
  selectedProvince.value = provinces.value[0];
  updateProvince();
  if (window.screen.width <= 768) {
    setTimeout(() => (dataView.value.state$$.viewRef = 'list'), 10);
  }
  window.addEventListener('resize', handleResize);
});
</script>

<style lang="scss" scoped>
@import '@/styles/panels.scss';
.p-button {
  &__p-component {
    &__data-view-action {
      visibility: hidden;
    }
  }
}
.btn-print {
  display: none;
  /* cursor: pointer;
  margin-top: 4%;
  color: #211915;
  & :deep(.pi) {
    font-size: 1.35rem;
  }
  &:hover {
    background-color: #FFDB00 !important;
    border-radius: 15%;
  } */
}
.header-before-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}
.container {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  overflow: auto;

  &__header {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 3rem;
    &__chart-road-type {
      box-shadow: $brand__boxshadow--bottom;
      border-radius: $brand__borderradius--large;
      padding: $brand__boxspace--medium * 2;
      display:flex;
      justify-content: center;
      flex-wrap: wrap;

      @media screen and (max-width: $brand__breakpoint--lg) {
        width: 100%;
        margin-bottom: 2rem;
      }
      @media screen and (min-width: $brand__breakpoint--lg) {
        width: 28%
      }
    }

    &__road-type-by-ccoo {
      @media screen and (max-width: $brand__breakpoint--lg) {
        width: 100%
      }
      @media screen and (min-width: $brand__breakpoint--lg) {
        width: 70%
      }

      box-shadow: $brand__boxshadow--bottom;
      border-radius: $brand__borderradius--large;
      padding: $brand__boxspace--medium * 2;


    }
  }

  & :deep(.p-chip) {
    display: none;
  }
  & :deep(.p-chip-remove-icon) {
    display: none;
  }
  & :deep(.data-view-action.p-button:nth-of-type(1)) {
    display:none
  }
  & :deep(.p-button-secondary) {
    color: #211915 !important;
    background-color: white !important;
    border: none !important;
    &:hover, &:focus, &.p-disabled {
      background-color: #FFDB00 !important;
      border-radius: 15%;
      opacity: 1 !important;
    }
  }

}
.container-chips {
  cursor: text;
  :not(.p-disabled):hover {
    box-shadow: none;
    border-bottom: none;
    border-color: white;
  }
  :active {
    border: none;
  }
}
.prime-chips {
  background: transparent;
  & :deep(.p-chips-token) {
    padding: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  & :deep(.p-chips-input-token) {
    display: none;
  }
  & :deep(.p-chips-token-icon) {
    display: none;
  }
  & :deep(.p-inputtext) {
    background-color: transparent;
    border-bottom: none;
  }
  & :deep(.p-chips-token-label) {
    font-style: italic;
  }
}
.data-view-class {
  & :deep(.data-view__header) {
    margin-top: 1vh;
  }
  & :deep(.p-progressbar-value.p-progressbar-value-animate) {
    background-color: #FFDB00;
  }
  & :deep(.p-dropdown-label.p-inputtext) {
    background-color: transparent;
    font-family: 'Roboto';
    font-size: 14px;
  }
  & :deep(.p-dropdown-label.p-inputtext.p-placeholder) {
    font-family: 'Roboto';
    font-size: 14px;
  }
  & :deep(.data-view__header__after) {
    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }
  & :deep(.data-view__header__after > button) {
    margin-left: 0 !important;
  }
  & :deep(.data-view__data .p-datatable) {
    height: fit-content;
  }
}
.dropdown {
  background: transparent;
  & :deep(.p-multiselect-label) {
    color: rgb(70, 70, 70);
    font-size: 14px;
  }
  & :deep(.p-multiselect-trigger-icon) {
    color: rgb(70, 70, 70);
  }
}
.query__paginator-container--small {
  display: block;
  & :deep(.p-highlight) {
    background-color: #FFDB00;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.query__paginator-container--big {
  display: none;
  & :deep(.p-highlight) {
    background-color: #FFDB00;
  }
  @media screen and (min-width: 768px) {
    display: block;
  }
}
</style>
