import
LengthCcooProvinceTypeView
  from '../views/length-ccoo-province-type/LengthCcooProvinceTypeView.vue';

export default [
  {
    path: '/longitud-ca-provincia-tipo',
    name: 'LengthCcooProvinceTypeView',
    component: LengthCcooProvinceTypeView,
    meta: {
      title: 'Longitud por CCAA, Provincia y tipo',
      bigImage: 'cabecera-carretera-01.jpg',
    },
  },
];
