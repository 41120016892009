import DownloadAllView from '../views/downloadAll/DownloadAll.vue';

export default [
  {
    path: '/descarga-catalogo',
    name: 'DownloadAllView',
    component: DownloadAllView,
    meta: {
      title: 'Descargar todo el catálogo',
      bigImage: 'cabecera-carretera-01.jpg',
    },
  },
];
