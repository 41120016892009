import {
  useAxios,
  useHandleApiError,
  // SearchApiModel,
} from '@sttedra_ui/framework';
import RoadTypeApiModel from './road-type.api-model';
/**
 * @typedef {import("@sttedra_ui/framework").AxiosErrorDataModel} AxiosErrorDataModel
 */

// #region Globals
const CONTEXT_BASE_PATH = 'Master/TiposVias';
const {base} = useAxios();
const {handleApiError} = useHandleApiError();
const timeoutRequestHttp = 30000;
// #endregion

const getAllRoadTypes = () => {
  const url = `${CONTEXT_BASE_PATH}`;

  return base
    .get(url, {
      timeout: timeoutRequestHttp,
    })
    .then((res) => {
      const dataList = res.data.map((obj) => new RoadTypeApiModel(obj));
      return dataList;
    })
    .catch(handleApiError);
};

export {getAllRoadTypes};
