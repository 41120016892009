import SectionsInaugurated from './sections-inaugurated.api-model';
import {SearchApiModel} from '@sttedra_ui/framework';
import {
  useAxios,
  useHandleApiError,
} from '@sttedra_ui/framework';

// #region Globals
const CONTEXT_BASE_PATH = 'Informacion/Inauguraciones';
const {base} = useAxios();
const {handleApiError} = useHandleApiError();
const timeoutRequestHttp = 30000;
// #endregion
const getSectionsInaugurated = (params = {}) => {
  const url = `/${CONTEXT_BASE_PATH}`;
  const toChangeFieldName = 'fechaServicioFin';
  const changeFieldName = 'fechaServicio';
  const searchModel = new SearchApiModel(params);
  if (!!searchModel?.filters?.length) {
    searchModel.filters.forEach((elem, index) => {
      if (elem.field === toChangeFieldName) {
        searchModel.filters[index].field = changeFieldName;
      }
    });
  }
  localStorage.setItem('currentCall', JSON.stringify(searchModel));
  return base
    .post(url, searchModel, {
      timeout: timeoutRequestHttp,
    })
    .then((res) => {
      const dataList = res.data.data.map((obj) => new SectionsInaugurated(obj));
      const dataParsed = [...dataList];
      dataParsed.forEach((element) => {
        element.longitud = String(element.longitud) + ' km';
      });
      return {
        data: [...dataParsed],
        pageSize: params.pageSize || 5,
        pageIndex: params.pageIndex || 1,
        totalResults: res.data.totalResults,
      };
    })
    .catch(handleApiError);
};

export {
  getSectionsInaugurated,
};
