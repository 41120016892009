/* eslint-disable max-len */
import {
  useAxios,
  useHandleApiError,
} from '@sttedra_ui/framework';
/**
 * @typedef {import("@sttedra_ui/framework").AxiosErrorDataModel} AxiosErrorDataModel
 */

// #region Globals
const CONTEXT_BASE_PATH = 'Export';
const {base} = useAxios();
const {handleApiError} = useHandleApiError();
const timeoutRequestHttp = 30000;
// #endregion
export default function printData(post, name) {
  const url = post + `/${CONTEXT_BASE_PATH}`;
  const searchModel = JSON.parse(localStorage.currentCall);
  return base
    .post(url, searchModel, {
      timeout: timeoutRequestHttp,
      responseType: 'arraybuffer',
    })
    .then((res) => {
      download(res.data, name);
    })
    .catch(handleApiError);
};

function download(response, name) {
  const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
  const objectUrl = URL.createObjectURL(blob);

  const downloadLink = document.createElement('a');
  downloadLink.href = objectUrl;
  downloadLink.download = name + '_export.xlsx';

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export {printData};
