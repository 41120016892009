import {computed, markRaw} from 'vue';
// import {TheLayout, useAuthorization, useRouter} from '@sttedra_ui/framework';
import {useRouter} from '@sttedra_ui/framework';
import CatalogLayout from '@/layouts/CatalogLayout';

// const {modelRef: authorizationModelRef} = useAuthorization();
const router = useRouter();

export default class AppViewState {
  // #region Layout
  // layout = computed(() => markRaw(router.currentRoute.value.meta?.layout || TheLayout));
  layout = computed(() => markRaw(router.currentRoute.value.meta?.layout || CatalogLayout));
  // roles = computed(() => authorizationModelRef.value.roles?.join(', ') || null);
  // #endregion

  // #region Examples
  // property1 = 'My custom value';

  // _property2 = '[Example] Init value';
  // get property2() {
  //   return this._property2;
  // }
  // set property2(newValue) {
  //   if (!newValue) {
  //     throw new Error('[app.view-state] set `property2`: `newValue` is falsy');
  //   }
  //   // ...
  //   // ...
  //   newValue = `[Example] ${newValue}`;
  //   // ...
  //   // ...
  //   this._property2 = newValue;
  // }
  // #endregion
}
