import {useToNumber, useCheckType, types} from '@sttedra_ui/framework';
import {kmNumberFormat} from '../util-locale';

class SectionsByRoadTypeApiModel {
  // #region Props
  carretera;
  provincia;
  pkInicio;
  pkFin;
  inicio;
  fin;
  longitud;
  // #endregion
  constructor({
    carretera,
    provincia,
    pkInicio,
    pkFin,
    inicio,
    fin,
    longitud,
  }) {
    this.carretera = this.#getString(carretera);
    this.provincia = this.#getString(provincia);
    const pkInicioRaw = this.#getNumber(pkInicio);
    this.pkInicio = kmNumberFormat.format(pkInicioRaw);
    const pkFinRaw = this.#getNumber(pkFin);
    this.pkFin = kmNumberFormat.format(pkFinRaw);
    this.inicio = this.#getString(inicio);
    this.fin = this.#getString(fin);
    const longitudRaw = this.#getNumber(longitud);
    this.longitud = kmNumberFormat.format(longitudRaw);
  }

  // #region Public methods

  // #endregion

  // #region Private methods
  #getNumber(newValue) {
    const number = useToNumber(newValue);
    return number >= 0 ? number : null;
  }
  #getString(newValue) {
    const expectedType = useCheckType(newValue, types.STRING);
    return expectedType ? newValue : null;
  }

  // #endregion
}

export default SectionsByRoadTypeApiModel;
