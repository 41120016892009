import {useToNumber, useCheckType, types} from '@sttedra_ui/framework';
import {kmNumberFormat} from '@/api/util-locale';

class CatalogSectionsApiModel {
  // #region Props
  ccaa;
  provincia;
  carretera;
  pkInicio;
  pkFin;
  longitud;
  inicio;
  fin;
  idTipoVia;
  tipoVia;
  idCcaa;
  idProvincia;
  // #endregion

  constructor({
    ccaa,
    provincia,
    carretera,
    pkInicio,
    pkFin,
    longitud,
    inicio,
    fin,
    idTipoVia,
    tipoVia,
    idCcaa,
    idProvincia,
  }) {
    this.ccaa = this.#getString(ccaa);
    this.provincia = this.#getString(provincia);
    this.carretera = this.#getString(carretera);
    const pkInicioRaw = this.#getNumber(pkInicio);
    this.pkInicio = kmNumberFormat.format(pkInicioRaw);
    const pkFinRaw = this.#getNumber(pkFin);
    this.pkFin = kmNumberFormat.format(pkFinRaw);
    const longitudRaw = this.#getNumber(longitud);
    this.longitud = kmNumberFormat.format(longitudRaw);
    this.inicio = this.#getString(inicio);
    this.fin = this.#getString(fin);
    this.idTipoVia = this.#getNumber(idTipoVia);
    this.tipoVia = this.#getString(tipoVia);
    this.idCcaa = this.#getNumber(idCcaa);
    this.idProvincia = this.#getNumber(idProvincia);
  }

  // #region Public methods

  // #endregion

  // #region Private methods
  #getNumber(newValue) {
    const number = useToNumber(newValue);
    return number >= 0 ? number : null;
  }
  #getString(newValue) {
    const expectedType = useCheckType(newValue, types.STRING);
    return expectedType ? newValue : null;
  }

  // #endregion
}

export default CatalogSectionsApiModel;
