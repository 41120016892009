import RoadNomenclature from './road-nomenclature-rce.api-model';
import {SearchApiModel} from '@sttedra_ui/framework';
import {
  useAxios,
  useHandleApiError,
} from '@sttedra_ui/framework';

// #region Globals
const CONTEXT_BASE_PATH = 'Informacion/Nomenclaturas';
const {base} = useAxios();
const {handleApiError} = useHandleApiError();
const timeoutRequestHttp = 30000;
// #endregion
const getRoadNomenclature = (params = {}) => {
  const url = `/${CONTEXT_BASE_PATH}`;
  const searchModel = new SearchApiModel(params);
  localStorage.setItem('currentCall', JSON.stringify(searchModel));
  return base
    .post(url, searchModel, {
      timeout: timeoutRequestHttp,
    })
    .then((res) => {
      const dataList = res.data.data.map((obj) => new RoadNomenclature(obj));
      const dataParsed = [...dataList];

      return {
        data: [...dataParsed],
        pageSize: params.pageSize || 5,
        pageIndex: params.pageIndex || 1,
        totalResults: res.data.totalResults,
      };
    })
    .catch(handleApiError);
};

export {
  getRoadNomenclature,
};
