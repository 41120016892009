import RemarksView from '../views/remarks/RemarksView.vue';

export default [
  {
    path: '/observaciones',
    name: 'RemarksView',
    component: RemarksView,
    meta: {
      title: 'Consideraciones previas y observaciones',
      bigImage: 'cabecera-carretera-01.jpg',
    },
  },
];
