import {useToNumber, useCheckType, types} from '@sttedra_ui/framework';
import {kmNumberFormat} from '@/api/util-locale';

class SectionsCededApiModel {
  // #region Props
  carretera;
  pkinicioT;
  pkfinT;
  tipoVia;
  tramoCedido;
  fechaCesion;
  fechaCesionFin;
  longitudCedida;
  administracionReceptora;
  // #endregion
  constructor({
    carretera,
    pkinicioT,
    pkfinT,
    tipoVia,
    tramoCedido,
    fechaCesion,
    longitudCedida,
    administracionReceptora,
  }) {
    this.carretera = this.#getString(carretera);
    this.pkinicioT = this.#getString(pkinicioT);
    this.pkfinT = this.#getString(pkfinT);
    this.tipoVia = this.#getString(tipoVia);
    this.tramoCedido = this.#getString(tramoCedido);
    this.fechaCesion = this.#getDate(fechaCesion);
    this.fechaCesionFin = this.fechaCesion;
    const longitudCedidaRaw = this.#getNumber(longitudCedida);
    this.longitudCedida = kmNumberFormat.format(longitudCedidaRaw);
    this.administracionReceptora = this.#getString(administracionReceptora);
  }

  // #region Public methods

  // #endregion

  // #region Private methods
  #getNumber(newValue) {
    const number = useToNumber(newValue);
    return number >= 0 ? number : null;
  }
  #getString(newValue) {
    const expectedType = useCheckType(newValue, types.STRING);
    return expectedType ? newValue : null;
  }
  #getDate(newValue) {
    const auxDate = Date.parse(newValue);
    const date = new Date(auxDate).toLocaleDateString('es-ES');
    return date;
  }

  // #endregion
}

export default SectionsCededApiModel;
