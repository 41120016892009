import {useCheckType, types} from '@sttedra_ui/framework';

class RoadNomenclature {
  // #region Props
  carretera;
  denominacion;
  itinerario;
  // #endregion
  constructor({
    carretera,
    denominacion,
    itinerario,
  }) {
    this.carretera = this.#getString(carretera);
    this.denominacion = this.#getString(denominacion);
    this.itinerario = this.#getString(itinerario);
  }

  #getString(newValue) {
    const expectedType = useCheckType(newValue, types.STRING);
    return expectedType ? newValue : null;
  }

  // #endregion
}

export default RoadNomenclature;
